import React, { useState } from "react";
import axios from "axios";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { ToastContainer, toast } from 'react-toastify';

const AddPrescriptionPopup = ({ open }) => {
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_doctorName: "",
    tb_prescriptionDate: "",
    tb_followupDate:"",
    tb_prescriptionDetails: "",
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  //Datepicker
const [datepickerVal, setdatepickerVal] = useState("");

const handleDatePicker = (event) => {
  setdatepickerVal(event.target.value);
  console.log(datepickerVal);
};

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const params = new URLSearchParams();
      axios({
        method: 'post',
        url: 'https://handler.optolinq.online/DIAGNOSIS/addPrescriptionHandler.php',
        data: params
      }).then(function (response) {
        console.log(response.data);
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '200') {
            toast.success(error.response.data['success'], { position: "top-center" });
          } else if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network Error', error.request, { position: "top-center" });
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while Adding a new Paitent!', { position: "top-center" });
    }
  };

  return (
    <>
    <ToastContainer/>
       <div>
      <Popup open={open} >
        <div className="editPopup" style={{height: "600px"}}>
        <div className="row ">
         {/* String Fields */}
         <div className="col-md-12  formGroup">
            <label htmlFor="tb_doctorName" className="form-label">
              Doctor Name
            </label>
            <input
              type="text"
              id="tb_doctorName"
              name="tb_doctorName"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
           {/* Datepicker */}
           <div className="formGroup col-6">
                <label htmlFor="datePicker" className="">
                Prescription Date
                </label>
                    <input
                    className="form-control formControl"
                    type="date"
                    id="datePicker"
                    value={datepickerVal}
                    onChange={handleDatePicker}
                />
            </div>
            <div className="formGroup col-md-6">
                  <label htmlFor="tb_followupDate" className="form-label">
                    Follow Up Date
                  </label>
                  <input
                    type="text"
                    id="tb_followupDate"
                    name="tb_followupDate"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="col-md-12 formGroup">
            <label htmlFor="tb_prescriptionDetails" className="form-label">
                Prescription Details
            </label>
            <textarea
              type="text"
              id="tb_prescriptionDetails"
              name="tb_prescriptionDetails"
              className="inputBox form-control textarea  text-dark tb_address"
              rows={5}
              cols="100"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row btnGroup">
          <div className="col-md-6 ">
            <button type="submit" className="btnCTA" onClick={handleSubmit}>
              Save
            </button>
          </div>
          <div className="col-md-6 ">
            <button
              type="button"
              className="btnCTA"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
        </div>
        </div>
        </Popup>
        </div>
    </>
  );
};

export default AddPrescriptionPopup;
