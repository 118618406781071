import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
const EditSuppliersMainDetails = ({ open }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_supplierName: '',
    tb_shortName: '',
    tb_supplierType: '',
    tb_tin: '',
    tb_vrn: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/Supplier/editSupplierMainDetailsHandler.php',
        // data: params,
        data: {
          'tb_supplier': formData.tb_supplier,
          'tb_shortName': formData.tb_shortName,
          'tb_supplierType': formData.tb_supplierType,
          'tb_tin': formData.tb_tin,
          'tb_vrn': formData.tb_vrn,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then(function (response) {
        if (response.status == '200') {
          console.log(response)
          toast.success(response.data['success'], { position: "top-center" });
          navigate(`/Homepage/ViewSuppliers`);
        }
        console.log(response.status)
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network Error', error.request, { position: "top-center" });
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while Editing Supplier details!', { position: "top-center" });
    }
  };
  
  return (
    <div>
      <ToastContainer/>
      <Popup open={open} closeOnDocumentClick>
        <div className="editPopup" >
        <div className="row mt-1">
                <div className="col-md-12 mt-1">
                  <label htmlFor="tb_supplierName" className="form-label">
                    Supplier Name
                  </label>
                  <input
                    type="text"
                    id="tb_supplierName"
                    name="tb_supplierName"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_shortName" className="form-label">
                    Short Name
                  </label>
                  <input
                    type="text"
                    id="tb_shortName"
                    name="tb_shortName"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_supplierType" className="form-label">
                    Supplier Type
                  </label>
                  <input
                    type="text"
                    id="tb_supplierType"
                    name="tb_supplierType"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_tin" className="form-label">
                    TIN
                  </label>
                  <input
                    type="text"
                    id="tb_tin"
                    name="tb_tin"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_vrn" className="form-label">
                    VRN
                  </label>
                  <input
                    type="text"
                    id="tb_vrn"
                    name="tb_vrn"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <br/>
              <div className="row btnGroup">
                <div className="col-md-12 ">
                  <button className="btn_edit1 col-6 " onClick={handleSubmit}>
                    Save
                  </button>
                </div>
              </div>
        </div>
      </Popup>
    </div>
  );
};

export default EditSuppliersMainDetails;
