import Login from '../IAMS/Login/Login';
import ForgotCredientials from '../IAMS/ForgotCredientials/ForgotCredientials';
import Acknowledgement from '../IAMS/acknowledgement/acknowledgement';
import AccountBlocked from '../IAMS/AccountBlocked';
import SetPassword from '../IAMS/SetPassword/SetPassword';
import HomePage from '../HomePage/HomePage';
import GodPage from '../GodMode/GodPage/GodPage';

const rootRoutes = [
    { path: '/', name: 'Login', component: Login },
    { path: '/ForgotCredientials', name: 'ForgotCredientials', component: ForgotCredientials },
    { path: '/Acknowledgement', name: 'Acknowledgement', component: Acknowledgement },
    { path: '/AccountBlocked', name: 'AccountBlocked', component: AccountBlocked },
    { path: '/Homepage/*', name: 'Homepage', component: HomePage },
    { path: '/GodPage/*', name: 'GodPage', component: GodPage },
    { path: '/SetPassword', name: 'SetPassword', component: SetPassword },
];

export default rootRoutes;