import React, { useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from '../../../../optoCustom.js';
import "react-toastify/dist/ReactToastify.css";

const ClientSearch = () => {
    const [formData, setFormData] = useState({
        tb_clientName: "",
        tb_primaryNumber: "",
        tb_secondaryNumber: "",
        tb_emailAddress: "",
        tb_physicalAddress: "",
    });

    const [clientData, setClientData] = useState([]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            axios({
                method: 'post',
                url: API_BASE_URL + 'MASTER/Client/filterClientHandler.php',
                data: {
                  'tb_clientName': formData.tb_clientName,
                  'tb_primaryNumber': formData.tb_primaryNumber,
                  'tb_secondaryNumber': formData.tb_secondaryNumber,
                  'tb_emailAddress': formData.tb_emailAddress,
                  'tb_physicalAddress': formData.tb_physicalAddress,
                },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
                .then((response) => {
                    if (response.status === 200 && response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS") {
                        // Extract the OPTO_MESSAGE array and set it to the state
                        setClientData(response.data.OPTO[0].OPTO_MESSAGE);
                        // toast.success("Clients retrieved successfully!", { position: "top-center" });
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            toast.error(error.response.data['error'], { position: "top-center" });
                        } else {
                            toast.error(error.message, { position: "top-center" });
                        }
                    } else {
                        toast.error('Network Error', { position: "top-center" });
                    }
                });
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while searching for clients!', { position: "top-center" });
        }
    };

    const handleReset = () => {
        setFormData({
            tb_clientName: "",
            tb_primaryNumber: "",
            tb_secondaryNumber: "",
            tb_emailAddress: "",
            tb_physicalAddress: "",
        });
        setClientData([]);
    };

    const columns = [
        {
            name: "Client Name",
            selector: (row) => row.client_name,
            sortable: true,
        },
        {
            name: "Primary Contact Number",
            selector: (row) => row.primary_number,
        },
        {
            name: "Secondary Contact Number",
            selector: (row) => row.secondary_number,
        },
        {
            name: "Email Address",
            selector: (row) => row.email_address,
        },
        {
            name: "Physical Address",
            selector: (row) => row.physical_address,
        },
        {
            name: "View",
            cell: (row) => (
                <button
                    type="button"
                    className="btnEYE"
                    style={{ border: "none", background: "none" }}
                >
                    <Link to={`/HomePage/ViewClient/${row.client_id}`}>
                        <FontAwesomeIcon
                            className="actionIcon"
                            icon={faEye}
                            style={{
                                color: "#717171",
                                width: "25px",
                                height: "auto",
                            }}
                        />
                    </Link>
                </button>
            ),
        },
    ];

    const tableStyles = {
        headRow: {
            style: {
                backgroundColor: "#E6E6E6",
                fontWeight: 600,
                borderBottom: "2px solid #D7D8D8",
            },
        },
    };

    return (
        <div>
            <ToastContainer />
            <div className="formHeading">CLIENT MANAGEMENT</div>
            <div className="formSubHeading">
                Manage your clients here. Fill in details to search for a client or add new.
            </div>
            <hr />
            <div className="inputContent">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-12 formGroup">
                            <label htmlFor="tb_clientName" className="form-label">
                                Client Name
                            </label>
                            <input
                                type="text"
                                id="tb_clientName"
                                name="tb_clientName"
                                className="inputBox form-control formControl text-dark"
                                value={formData.tb_clientName}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-12 formGroup">
                            <label htmlFor="tb_primaryNumber" className="form-label">
                                Primary Contact
                            </label>
                            <input
                                type="text"
                                id="tb_primaryNumber"
                                name="tb_primaryNumber"
                                className="inputBox form-control formControl text-dark"
                                value={formData.tb_primaryNumber}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-12 formGroup">
                            <label htmlFor="tb_emailAddress" className="form-label">
                                Email Address
                            </label>
                            <input
                                type="email"
                                id="tb_emailAddress"
                                name="tb_emailAddress"
                                className="inputBox form-control formControl text-dark"
                                value={formData.tb_emailAddress}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-12 formGroup">
                            <label htmlFor="tb_physicalAddress" className="form-label">
                                Physical Address
                            </label>
                            <textarea
                                id="tb_physicalAddress"
                                name="tb_physicalAddress"
                                className="inputBox form-control formControl text-dark"
                                rows={5}
                                value={formData.tb_physicalAddress}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row btnGroup">
                        <div className="col-md-4">
                            <button type="button" className="btnCTA" onClick={handleReset}>
                                Reset
                            </button>
                        </div>
                        <div className="col-md-4">
                            <button type="submit" className="btnCTA">
                                Filter
                            </button>
                        </div>
                        <div className="col-md-4">
                            <Link to={"/Homepage/AddClient"}>
                                <button type="button" className="btnCTA">
                                    Add New Client
                                </button>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>

            {/* DataTable to display client data */}
            <DataTable
                className="resultData"
                columns={columns}
                data={clientData} // Data received from API
                pagination
                responsive
                id="myTable"
                customStyles={tableStyles}
            />
        </div>
    );
};

export default ClientSearch;
