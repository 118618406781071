import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import { useNavigate } from "react-router-dom";
const AddDiagnosis = () => {
  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_patientName: "",
    tb_clientName: "",
    tb_doctor: "",
    tb_diagnosisDate: "",
    tb_gender: "",
    tb_healthIssue: "",
    tb_vrre: "",
    tb_vrle: "",
    tb_srre: "",
    tb_srle: "",
    tb_orre: "",
    tb_orle: "",
    tb_doctorDiagnosis: "",
    tb_notes: "",


  });


  const [createdByOptions, setCreatedByOptions] = useState([]); // State to store "Created by" dropdown options
  const [doctorOptions, setDoctorOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios.get(
          "http://192.168.29.186/optolinq-web/handler/COMMON/getClientList.php"
        ) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.client_id, // Adjust based on your API response structure
          label: option.client_name, // Adjust based on your API response structure
        }));
        setCreatedByOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  useEffect(() => {
    
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios.get(
          "http://192.168.29.186/optolinq-web/handler/COMMON/getDoctorList.php"
        ) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.client_id, // Adjust based on your API response structure
          label: option.client_name, // Adjust based on your API response structure
        }));
        setDoctorOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
   // Handle form submission
   const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/Diagnosis/addDiagnosisHandler.php',
        // data: params,
        data: {
          'tb_patientName': formData.tb_patientName,
          'tb_clientName': formData.tb_clientName,
          'tb_doctor': formData.tb_doctor,
          'tb_diagnosisDate': formData.tb_diagnosisDate,
          'tb_gender': formData.tb_gender,
          'tb_healthIssue': formData.tb_healthIssue,
          'tb_vrre': formData.tb_vrre,
          'tb_vrle': formData.tb_vrle,
          'tb_srre': formData.tb_srre,
          'tb_srle': formData.tb_srle,
          'tb_orre': formData.tb_orre,
          'tb_orle': formData.tb_orle,
          'tb_doctorDiagnosis': formData.tb_doctorDiagnosis,
          'tb_notes': formData.tb_notes,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then(function (response) {
        if (response.status == '200') {
          console.log(response)
          toast.success("Diagnosis added successfully!", { position: "top-center" });
          navigate(`/Homepage/ViewDaignosis`);
        }
        console.log(response.status)
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network Error', error.request, { position: "top-center" });
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while Adding a new Client!', { position: "top-center" });
    }
  };

  return (
    <>
    <ToastContainer/>
      {/* Heading and Sub Heading */}
      <div className="formHeader d-flex align-items-center justify-content-between">
        <div className="headings">
          <div className="formHeading">ADD DIAGNOSIS</div>
          <div className="formSubHeading">
            Fill up the details below to add a new Diagnosis
          </div>
        </div>
        <Link to={"/Homepage/SearchDiagnosis"}>
          <button className="btnClose d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#ffffff", height: "20px" }}
              className="mx-auto my-auto"
            />
          </button>
        </Link>
      </div>
      <hr />
      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row ">
          {/* String Fields */}
          <div className="col-md-12  formGroup">
            <label htmlFor="tb_patientName" className="form-label">
              Patient Name
            </label>
            <input
              type="text"
              id="tb_patientName"
              name="tb_patientName"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-6">
          <label htmlFor="singleSelect">Cilent Name</label>
          <Select
            options={createdByOptions}
            placeholder="Select Client"
            name="tb_clientName"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #B0B0B0",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            isClearable
            onChange={handleChange}
          />
        </div>
        <div className="formGroup col-6">
          <label htmlFor="singleSelect">Doctor</label>
          <Select
            options={doctorOptions}
            placeholder="Select Doctor"
            name="tb_doctor"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #B0B0B0",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            isClearable
            onChange={handleChange}
          />
        </div>
          <div className="formGroup col-md-6">
                  <label htmlFor="tb_diagnosisDate" className="form-label">
                    Diagnosis Date
                  </label>
                  <input
                    type="text"
                    id="tb_diagnosisDate"
                    name="tb_diagnosisDate"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-6">
            <label className="form-check-label" htmlFor="gender">
              Gender
            </label>
            <div className="radioGroup d-flex justify-content-start">
              <div className="form-check me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tb_gender"
                  id="genderMale"
                  value="male"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="genderMale">
                  Male
                </label>
              </div>
              <div className="form-check me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tb_gender"
                  id="genderFemale"
                  value="female"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="genderFemale">
                  Female
                </label>
              </div>
            </div>
          </div>
          {/* Contact Number Field */}
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_healthIssue" className="form-label">
              Health Issue
            </label>
            <textarea
              type="text"
              id="tb_healthIssue"
              name="tb_healthIssue"
              className="inputBox form-control textarea  text-dark tb_address"
              rows={5}
              cols="100"
              onChange={handleChange}
            />
          </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_vrre" className="form-label">
                    VRRE
                  </label>
                  <input
                    type="text"
                    id="tb_vrre"
                    name="tb_vrre"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_vrle" className="form-label">
                    VRLE
                  </label>
                  <input
                    type="text"
                    id="tb_vrle"
                    name="tb_vrle"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_srre" className="form-label">
                    SRRE
                  </label>
                  <input
                    type="text"
                    id="tb_srre"
                    name="tb_srre"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_srle" className="form-label">
                    SRLE
                  </label>
                  <input
                    type="text"
                    id="tb_srle"
                    name="srle"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_orre" className="form-label">
                    ORRE
                  </label>
                  <input
                    type="text"
                    id="tb_orre"
                    name="tb_orre"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_orle" className="form-label">
                    ORLE
                  </label>
                  <input
                    type="text"
                    id="tb_orle"
                    name="tb_orle"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="col-md-12 formGroup">
            <label htmlFor="tb_notes" className="form-label">
                Notes
            </label>
            <textarea
              type="text"
              id="tb_notes"
              name="tb_notes"
              className="inputBox form-control textarea  text-dark tb_address"
              rows={5}
              cols="100"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_doctorDaignosis" className="form-label">
              Doctor Diagnosis
            </label>
            <textarea
              type="text"
              id="tb_doctorDiagnosis"
              name="tb_doctorDiagnosis"
              className="inputBox form-control textarea  text-dark tb_address"
              rows={5}
              cols="100"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row btnGroup">
          <div className="col-md-6 ">
            <button type="submit" className="btnCTA">
              Save
            </button>
          </div>
          <div className="col-md-6 ">
            <button
              type="button"
              className="btnCTA"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddDiagnosis;
