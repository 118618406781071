import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import AddPatient from "../Patients/AddPatient";
import Mainpopup from "../../../HomePage/Masters/Suppliers/EditSuppliersMainDetails";
import Contactpopup from "../../../HomePage/Masters/Suppliers/EditSuppliersContactDetails";
import Bankpopup from "../../../HomePage/Masters/Suppliers/EditSuppliersBankDetails";
import axios from "axios";
import { API_BASE_URL } from "../../../../optoCustom";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
const ViewSuppliers = () => {
  const {supplierId} = useParams();
  const [mainIsOpen, setmainIsOpen] = useState(false);
  const [ContactIsOpen, setContactIsOpen] = useState(false);
  const [bankIsOpen, setbankIsOpen] = useState(false);
  const [mainDetails, setMainDetails] = useState([]);
  const [contactDetails, setContactDetails] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [historyDetails, setHistoryDetails] = useState([]);
  const [voucherDetails, setVoucherDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          method: "post",
          url: API_BASE_URL + "MASTER/Supplier/viewSupplierDetailsHandler.php",
          data: {
            tb_supplierId: supplierId,
          },
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });

        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          const responsedData = response.data.OPTO[0].OPTO_MESSAGE;

          // Set state for each section of the JSON data
          setMainDetails(responsedData.main_details[0] || {});
          setContactDetails(responsedData.contact_details || []);
          setBankDetails(responsedData.bank_details || []);
          setHistoryDetails(responsedData.history_details || []);
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            toast.error(error.response.data["error"], {
              position: "top-center",
            });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else {
          toast.error("Network Error", { position: "top-center" });
        }
      }
    };

    fetchData();
  }, [supplierId]);

  const toggleMainPopup = () => {
    setmainIsOpen(!mainIsOpen);
  };

  const toggleContactPopup = () => {
    setContactIsOpen(!ContactIsOpen);
  };

  const toggleBankPopup = () => {
    setbankIsOpen(!bankIsOpen);
  };

  const bankAccordionColumns = [
    {
      name: "Bank Account",
      selector: (row) => row.supplier_id,
      sortable: true,
    },
    {
      name: "Branch Name",
      selector: (row) => row.bank_branch_name,
      sortable: true,
    },
    {
      name: "Branch Code",
      selector: (row) => row.bank_branch_code,
      sortable: true,
    },
    {
      name: "Swift",
      selector: (row) => row.bank_swift_code,
      sortable: true,
    },
    {
      name: "ABA",
      selector: (row) => row.bank_aba_number,
      sortable: true, // selector:
    },
    {
      name: "iBAN",
      selector: (row) => row.bank_iban_number,
      sortable: true,
    },
    {
      name: "Sort Code",
      selector: (row) => row.bank_sort_code,
      sortable: true,
    },
  ];

  const vouchersAccordionColumns = [
    {
      name: "Voucher",
      // selector:
    },
    {
      name: "Voucher Date",
      // selector:
    },
    {
      name: "Bank Account",
      // selector:
    },
    {
      name: "Voucher Amount",
      // selector:
    },
    {
      name: "Action",
      // selector:
    },
  ];

  const historyAccordionColumns = [
    {
      name: "Name",
      selector: (row) => row.supplier_name,
      sortable: true,
    },
    {
      name: "TIN",
      selector: (row) => row.tin,
      sortable: true,
    },
    {
      name: "VRN",
      selector: (row) => row.vrn,
      sortable: true,
    },
    {
      name: "Changed On",
      selector: (row) => row.changed_on,
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row.changed_by,
      sortable: true,
    },
  ];

  return (
    <div className="viewFormContainer row">
      <div className="formHeading">VIEW SUPPLIERS DETAILS</div>
      <div className="formSubHeading">View the Suppliers details below</div>
      <hr />
      <div className="accordion col-9" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Main
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="row mt-1">
                <div className="col-md-12 mt-1">
                  <label htmlFor="tb_supplierName" className="form-label">
                    Supplier Name
                  </label>
                  <input
                    type="text"
                    id="tb_supplierName"
                    name="tb_supplierName"
                    value={mainDetails.supplier_name}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_supplierShortName" className="form-label">
                    Supplier Short Name
                  </label>
                  <input
                    type="text"
                    id="tb_supplierShortName"
                    name="tb_supplierShortName"
                    value={mainDetails.supplier_short_name}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="supplier_type" className="form-label">
                    Supplier type
                  </label>
                  <input
                    type="text"
                    id="supplier_type"
                    name="supplier_type"
                    value={mainDetails.supplier_type}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_tin" className="form-label">
                    TIN
                  </label>
                  <input
                    type="text"
                    id="tb_tin"
                    name="tb_tin"
                    value={mainDetails.tin}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_vrn" className="form-label">
                    VRN
                  </label>
                  <input
                    type="text"
                    id="tb_vrn"
                    name="tb_vrn"
                    value={mainDetails.vrn}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
              </div>
              <br/>
              <div className="row btnGroup">
                <div className="col-md-12">
                  <button className="btn_edit col-12" onClick={toggleMainPopup}>
                    Edit
                  </button>
                  <Mainpopup
                    id="mainDetailspopup"
                    open={mainIsOpen}
                    onClose={toggleMainPopup}
                    value={mainDetails.supplierId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Details Accordion */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Contact Details
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="tb_physicalAddress" className="form-label">
                    Physical Address
                  </label>
                  <input
                    type="text"
                    id="tb_physicalAddress"
                    name="tb_physicalAddress"
                    value={contactDetails.physical_address}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_postBox" className="form-label">
                    P.O.Box
                  </label>
                  <input
                    type="text"
                    id="tb_postBox"
                    name="tb_postBox"
                    value={contactDetails.post_box}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_primaryNumber" className="form-label">
                    Primary Contact
                  </label>
                  <input
                    type="text"
                    id="tb_primaryNumber"
                    name="tb_primaryNumber"
                    value={contactDetails.primary_number}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_secondaryContact" className="form-label">
                    Secondary Contact
                  </label>
                  <input
                    type="text"
                    id="tb_secondaryContact"
                    name="tb_secondaryContact"
                    value={contactDetails.secondary_number}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="tb_emailAddress" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="text"
                    id="tb_emailAddress"
                    name="tb_emailAddress"
                    value={contactDetails.email_address}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
              </div>
              <div className="row btnGroup">
                <div className="col-md-12">
                  <button
                    className="btn_edit col-12"
                    onClick={toggleContactPopup}
                  >
                    Edit
                  </button>
                  <Contactpopup
                    id="Contactpopup"
                    open={ContactIsOpen}
                    onClose={toggleContactPopup}
                    value={contactDetails.supplierId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Bank Details
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={bankAccordionColumns}
                    data={bankDetails}
                    pagination
                    responsive
                    id="bankAccordionTable"
                  ></DataTable>
                  <button className="btn_edit col-12" onClick={toggleBankPopup}>
                    Edit
                  </button>
                  <Bankpopup
                    id="bankpopup"
                    open={bankIsOpen}
                    onClose={toggleBankPopup}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Vouchers
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={vouchersAccordionColumns}
                    data={voucherDetails}
                    pagination
                    responsive
                    id="VoucherAccordionTable"
                  ></DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              History
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse accordion"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={historyAccordionColumns}
                    data={historyDetails}
                    pagination
                    responsive
                    id="historyAccordionTable"
                  ></DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="linkContainer col-3 d-flex flex-column">
        <div className="formSubHeading">Quick links</div>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
      </div>
    </div>
  );
};

export default ViewSuppliers;
