import React, { useState } from "react";
import "./GodPage.css";
import img_optolinqName from "../../../Assets/OPTO LINQ_1080.png";
import optolinqlogo from "../../../Assets/OptoIcon.ico";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faEarthAfrica,
  faBell,
  faArrowRightFromBracket,
  faClose,
  faBorderAll
} from "@fortawesome/free-solid-svg-icons";
import { Link, Route, Routes } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { GodpageRoutes } from "../../Routes/OptoRoute";

const GodPage = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleLinkClick = () => {
    setIsCollapsed(true);
  };
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className="custom-tooltip">
      {props}
    </Tooltip>
  );

  return (
    <div className="d-flex flex-row">
      {/* Sidebar*/}
      <div
        className={`fixed-top sidebar bg-white vh-100 ${
          isCollapsed ? "collapsed" : ""
        }`}
        style={{ minWidth: isCollapsed ? "80px" : "250px" }}
      >
        <div className="p-3" style={{ height: "50px" }}>
          {isCollapsed ? (
            <img
              src={optolinqlogo}
              className="logo2"
              alt="logo2"
              style={{ width: "auto", height: "40px" }}
            />
          ) : (
            <img
              src={img_optolinqName}
              className="logo"
              alt="logo"
              style={{ width: "100%", height: "auto" }}
            />
          )}
          <ul className="nav flex-column">
            <li className={`nav-item ${isCollapsed ? "mx-auto" : ""}`}>
              <Link
                to={"/HomePage"}
                className="nav-link  text-black p-2 d-flex align-items-center"
                onClick={handleLinkClick}
              >
                <FontAwesomeIcon
                  className="sidebaricon"
                  icon={faBorderAll}
                  style={{ color: "#717171", width: "25px", height: "auto" }}
                />
                <span>Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <OverlayTrigger
                placement="right"
                overlay={renderTooltip("Company")}
              >
                <Link
                  to={"/GodPage/Company"}
                  className="appiontmentHover nav-link p-2 text-black d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    className="sidebaricon"
                    icon={faEarthAfrica}
                    style={{
                      color: "#717171",
                      width: "25px",
                      height: "auto",
                    }}
                  />
                  <span>Company</span>
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow-1 d-flex flex-column" id="mainContent">
        <nav className="navbar bg-body-tertiary d-flex flex-row justify-content-between align-items-center sticky-top">
          <div className="div1 d-flex align-items-center">
            <img
              src={optolinqlogo}
              className="logo2-navbar"
              alt="logo2"
              style={{ width: "auto", height: "40px" }}
            />
            <FontAwesomeIcon
              icon={faBars}
              onClick={toggleSidebar}
              className="menu mr-3 space-right2 cursor-pointer"
            />
            <div className="page-name ">Dashboard</div>
          </div>
          <div className="div2 d-flex align-items-center justify-content-between">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip("Notifications")}
            >
              <FontAwesomeIcon
                className="navbaricon mx-3"
                icon={faBell}
                style={{
                  color: "#ffffff",
                  width: "25px",
                  height: "auto",
                }}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip("Logout")}
            >
              <FontAwesomeIcon
                className="navbaricon mx-3"
                icon={faArrowRightFromBracket}
                style={{
                  color: "#ffffff",
                  width: "25px",
                  height: "auto",
                }}
              />
            </OverlayTrigger>
          </div>
        </nav>

        <div className="innerContent flex-grow-1 ">
          {/* Breadcrumb */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/GodPage"} className="link">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <a href="/" className="link">
                  Library
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a href="/" className="link">
                  Data
                </a>
              </li>
            </ol>
          </nav>

          {/* Form Card and its results */}
          <div className="formCard flex-grow-1 ">
            <Routes>
              {GodpageRoutes.map((route, idx) => {
                const Component = route.component; // Store component in a variable
                return (
                  <Route
                    key={idx} // It's important to give each route a unique key
                    path={route.path}
                    element={<Component />} // Render component as JSX
                  />
                );
              })}
            </Routes>
          </div>
        </div>
        {/* Main Content Area */}
        <div className="footer-div fixed-bottom">
          <div className="footer d-flex flex-row justify-content-between">
            <div className="left-part mx-3">Copyright Information</div>
            <div className="right-part d-flex flex-row mx-3">
              <div className="loginCurrent mx-3">Current Login : XX:XX</div>
              <div className="loginLast mx-3">Last Login : XX:XX</div>
              <div className="version mx-3">Version X.1.2.3</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GodPage;
