import React, {  useEffect,useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from '../../../../optoCustom.js';
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const SearchStaff = () => {
  const [formData, setFormData] = useState([]);
  const [staffData, setStaffData] = useState([]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      // console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/Staff/filterStaffHandler.php',
        // data: params,
        data: {
          'tb_staffName': formData.tb_staffName,
          'db_departmentName': formData.db_departmentName,
          'db_designationName': formData.db_designationName,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then((response) => {
        if (response.status === 200 && response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS") {
            // Extract the OPTO_MESSAGE array and set it to the state
            setStaffData(response.data.OPTO[0].OPTO_MESSAGE);
            console.log(response.data.OPTO[0].OPTO_MESSAGE)
            // toast.success("Clients retrieved successfully!", { position: "top-center" });
        }
    })
    .catch((error) => {
        if (error.response) {
            if (error.response.status === 401) {
                toast.error(error.response.data['error'], { position: "top-center" });
            } else {
                toast.error(error.message, { position: "top-center" });
            }
        } else {
            toast.error('Network Error', { position: "top-center" });
        }
    });
} catch (error) {
console.error('Error:', error);
toast.error('An error occurred while searching for staff!', { position: "top-center" });
}
};
  
  const handleReset = () => {
    setFormData({
      tb_staffName: "",
      db_departmentName: "",
      db_designationName: "",
    });
    setStaffData([]);
};
  const columns = [
    {
      name: "Staff Name",
      selector: (row) => row.staff_name,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => row.department_id,
      sortable: true,
    },
    {
      name: "Designation",
      selector: (row) => row.designation_id,
      sortable: true,
    },

    {
      name: "View",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/HomePage/ViewStaff/${row.staff_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
    },
  ];


  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#E6E6E6",
        fontWeight: 600,
        borderBottom: "2px solid #D7D8D8",
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };


  return (
    <div>
      <ToastContainer/>
      {/* Heading and Sub Heading */}
      <div className="formHeading">STAFF MANAGEMENT</div>
      <div className="formSubHeading">
        Manage your staff here. Fill in details to search for a staff or add
        new
      </div>
      <hr />
      {/* inputs */}

      {/* Text Input Label-above */}
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* String Fields */}
            <div className="col-md-12  formGroup">
              <label htmlFor="tb_staffName" className="form-label">
                Staff Name
              </label>
              <input
                type="text"
                id="tb_staffName"
                name="tb_staffName"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
                
              />
            </div>
            <div className="col-md-6  formGroup">
              <label htmlFor="db_departmentName" className="form-label">
               Department
              </label>
              <input
                type="text"
                id="db_departmentName"
                name="db_departmentName"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6  formGroup">
              <label htmlFor="db_designationName" className="form-label">
                Designation
              </label>
              <input
                type="text"
                id="db_designationName"
                name="db_designationName"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <button
                type="reset"
                className="btnCTA"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA">
                Filter
              </button>
            </div>
            <div className="col-4">
              <Link to={"/Homepage/AddStaff"}>
                <button type="button" className="btnCTA">
                  Add New Staff
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>

      <DataTable
        className="resultData"
        columns={columns}
        data={staffData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      ></DataTable>
    </div>
  );
};

export default SearchStaff;
