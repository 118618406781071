import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from '../../../../optoCustom.js';

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const PatientSearch = () => {
  const [formData, setFormData] = useState([]);

  const [patientData, setPatientData] = useState([]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleReset = () => {
      setFormData({
          tb_clientName: "",
          tb_primaryNumber: "",
          tb_secondaryNumber: "",
          tb_emailAddress: "",
          tb_physicalAddress: "",
      });
      setPatientData([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      // console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/Patient/filterPatientHandler.php',
        // data: params,
        data: {
          'tb_clientName': formData.tb_clientName,
          'tb_patientName': formData.tb_patientName,
          'tb_primaryNumber': formData.tb_primaryNumber,
          'tb_secondaryNumber': formData.tb_secondaryNumber,
          'tb_occupation': formData.tb_occupation,
          'tb_physicalAddress': formData.tb_physicalAddress,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then((response) => {
        if (response.status === 200 && response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS") {
            // Extract the OPTO_MESSAGE array and set it to the state
            setPatientData(response.data.OPTO[0].OPTO_MESSAGE);
            console.log(response.data.OPTO[0].OPTO_MESSAGE)
            // toast.success("Clients retrieved successfully!", { position: "top-center" });
        }
    })
    .catch((error) => {
        if (error.response) {
            if (error.response.status === 401) {
                toast.error(error.response.data['error'], { position: "top-center" });
            } else {
                toast.error(error.message, { position: "top-center" });
            }
        } else {
            toast.error('Network Error', { position: "top-center" });
        }
    });
} catch (error) {
console.error('Error:', error);
toast.error('An error occurred while searching for clients!', { position: "top-center" });
}
};

  const columns = [
    {
      name: "Patient Name",
      selector: (row) => row.patient_name,
            sortable: true,
    },
    {
      name: "Client Name",
      selector: (row) => row.client_name,
      sortable: true,
    },
    {
      name: "Primary Contact Number",
      selector: (row) => row.primary_number,
    },
    {
      name: "Email Address",
      selector: (row) => row.email_address,
    },
    {
      name: "View",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/HomePage/ViewPatient/${row.patient_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
    },
  ];


  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#E6E6E6",
        fontWeight: 600,
        borderBottom: "2px solid #D7D8D8",
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };


  
  return (
    <div>
      <ToastContainer/>
      {/* Heading and Sub Heading */}
      <div className="formHeading">PATIENT MANAGEMENT</div>
      <div className="formSubHeading">
        Manage your patients here. Fill in details to search for a patient or
        add new
      </div>
      <hr />
      {/* inputs */}

      {/* Text Input Label-above */}
      <div className="inputContent">
        <form onClick={handleSubmit} >
          <div className="row">
            {/* String Fields */}
            <div className="col-md-6  formGroup">
              <label htmlFor="tb_patientName" className="form-label">
                Patient Name
              </label>
              <input
                type="text"
                id="tb_patientName"
                name="tb_patientName"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6  formGroup">
              <label htmlFor="tb_clientName" className="form-label">
                Client Name
              </label>
              <input
                type="text"
                id="tb_clientName"
                name="tb_clientName"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6  formGroup">
              <label htmlFor="tb_primaryNumber" className="form-label">
                Primary Contact
              </label>
              <input
                type="text"
                id="tb_primaryNumber"
                name="tb_primaryNumber"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6  formGroup">
              <label htmlFor="tb_occupation" className="form-label">
                Occupation
              </label>
              <input
                type="email"
                id="tb_occupation"
                name="tb_occupation"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
            {/* Contact Number Field */}
            <div className="col-md-12 formGroup">
              <label htmlFor="tb_PhysicalAddress" className="form-label">
                Physical Address
              </label>
              <textarea
                type="text"
                id="tb_PhysicalAddress"
                name="tb_PhysicalAddress"
                className="inputBox form-control textarea  text-dark tb_address"
                rows={5}
                cols="100"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-6">
              <button
                type="reset"
                className="btnCTA"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="col-md-6">
              <button type="button" className="btnCTA">
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>

      <DataTable
        className="resultData col-md-12"
        columns={columns}
        data={patientData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      ></DataTable>

        <div className="col-md-8 mx-auto">
          <Link to={"/Homepage/AddPatient"}>
            <button className="btnCTA">Add New Patient</button>
          </Link>
        </div>
    </div>
  );
}

export default PatientSearch;