import React, {  useEffect,useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from '../../../../optoCustom.js';
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const SearchSuppliers = () => {
  const [formData, setFormData] = useState([]);
  const [supplierData, setsupplierData] = useState([]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleReset = () => {
      setFormData({
          tb_supplier: "",
          tb_primaryNumber: "",
          tb_secondaryNumber: "",
          tb_emailAddress: "",
          tb_physicalAddress: "",
          tb_poBox:"",
      });
      setsupplierData([]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/Supplier/filterSupplierHandler.php',
        // data: params,
        data: {
          'tb_supplier': formData.tb_supplier,
          'tb_secondaryNumber': formData.tb_secondaryNumber,
          'tb_primaryNumber': formData.tb_primaryNumber,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_poBox': formData.tb_poBox,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then((response) => {
        if (response.status === 200 && response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS") {
            // Log response to check the data
            console.log(response.data.OPTO[0].OPTO_MESSAGE);
            // Set supplierData to an array
            setsupplierData(response.data.OPTO[0].OPTO_MESSAGE || []);
        }
      }).catch((error) => {
        if (error.response) {
          toast.error(error.response.data['error'] || error.message, { position: "top-center" });
        } else {
          toast.error('Network Error', { position: "top-center" });
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while searching for clients!', { position: "top-center" });
    }
  };
  

  const columns = [
    {
      name: "Supplier Name",
      selector: (row) => row.supplier_name,
      sortable: true,
    },
    {
      name: "Primary Contact Number",
      selector: (row) => row.primary_number,
      sortable: true,
    },
    {
      name: "Email Address",
      selector: (row) => row.email_address,
      sortable: true,
    },

    {
      name: "View",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/HomePage/ViewSuppliers/${row.supplier_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
    },
  ];


  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#E6E6E6",
        fontWeight: 600,
        borderBottom: "2px solid #D7D8D8",
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };


  return (
    <div>
      <ToastContainer/>
      {/* Heading and Sub Heading */}
      <div className="formHeading">SUPPLIER MANAGEMENT</div>
      <div className="formSubHeading">
        Manage your Supplier here. Fill in details to search for a Supplier or add
        new
      </div>
      <hr />
      {/* inputs */}

      {/* Text Input Label-above */}
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* String Fields */}
            <div className="col-md-12  formGroup">
              <label htmlFor="tb_supplier" className="form-label">
                Supplier Name
              </label>
              <input
                type="text"
                id="tb_supplier"
                name="tb_supplier"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-12  formGroup">
              <label htmlFor="tb_primaryNumber" className="form-label">
                Primary Contact
              </label>
              <input
                type="text"
                id="tb_primaryNumber"
                name="tb_primaryNumber"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
            <div className="col-12  formGroup">
              <label htmlFor="tb_emailAddress" className="form-label">
                Email Address
              </label>
              <input
                type="email"
                id="tb_emailAddress"
                name="tb_emailAddress"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
            {/* Contact Number Field */}
            <div className="col-md-12 formGroup">
              <label htmlFor="tb_physicalAddress" className="form-label">
                Physical Address
              </label>
              <textarea
                type="text"
                id="tb_physicalAddress"
                name="tb_physicalAddress"
                className="inputBox form-control textarea  text-dark tb_address"
                onChange={handleChange}
                rows={5}
                cols="100"
              />
            </div>
            <div className="col-12  formGroup">
            <label htmlFor="tb_poBox" className="form-label">
              Post Box
            </label>
            <input
              type="text"
              id="tb_poBox"
              name="tb_poBox"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <button
                type="button"
                className="btnCTA"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA" onClick={handleSubmit}>
                Filter
              </button>
            </div>
            <div className="col-4">
              <Link to={"/Homepage/AddSuppliers"}>
                <button type="button" className="btnCTA">
                  Add New Supplier
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>

      <DataTable
        className="resultData"
        columns={columns}
        data={supplierData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      ></DataTable>
    </div>
  );
};

export default SearchSuppliers;
