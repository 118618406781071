import React from "react";
import Eye from "../../../../Assets/Eye.svg";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
const EditSuppliersBankDetails = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [formData, setData] = useState([]);
  const [branchDetails, setBranchDetails] = useState({
    tb_bankAccount: "",
    tb_branchName: "",
    tb_branchCode: "",
    tb_swift: "",
    tb_aba: "",
    tb_iBan: "",
    tb_sortCode: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get("https://jsonplaceholder.typicode.com/users")
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBranchDetails({ ...branchDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      // console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/Supplier/editSupplierBankDetailsHandler.php',
        // data: params,
        data: {
          'tb_bankAccount': formData.tb_bankAccount,
          'tb_branchName': formData.tb_branchName,
          'tb_branchCode': formData.tb_branchCode,
          'tb_swift': formData.tb_swift,
          'tb_aba': formData.tb_aba,
          'tb_iBan': formData.tb_iBan,
          'tb_sortCode': formData.tb_sortCode,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then(function (response) {
        if (response.status == '200') {
          console.log(response)
          toast.success(response.data['success'], { position: "top-center" });
          navigate(`/Homepage/ViewSuppliers`);
        }
        console.log(response.status)
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network Error', error.request, { position: "top-center" });
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while Editing Supplier Detials!', { position: "top-center" });
    }
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Column 2",
      selector: (row) => row.address.street,
    },
    {
      name: "Column 3",
      selector: (row) => row.phone,
    },
    {
      name: "Column 4",
      selector: (row) => row.website,
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <img src={Eye} className="inventory" alt="inventory" />
        </button>
      ),
    },
  ];

  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#E6E6E6",
        fontWeight: 600,
        borderBottom: "2px solid #D7D8D8",
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  return (
    <div>
      <ToastContainer/>
      <Popup open={open} closeOnDocumentClick onClose={onClose}>
        <div className="editPopup" style={{ height: "600px" }}>
          <form onSubmit={handleSubmit}>
            <div className="row mt-1 popup">
              <div className="formGroup col-12 mt-1">
                <label htmlFor="tb_bankAccount" className="form-label">
                  Bank Account
                </label>
                <input
                  type="text"
                  id="tb_bankAccount"
                  name="tb_bankAccount"
                  className="inputBox form-control formControl  text-dark"
                  value={branchDetails.bankAccount}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formGroup col-6 mt-1">
                <label htmlFor="tb_branchName" className="form-label">
                  Branch Name
                </label>
                <input
                  type="text"
                  id="tb_branchName"
                  name="tb_branchName"
                  className="inputBox form-control formControl  text-dark"
                  value={branchDetails.branchName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formGroup col-6 mt-1">
                <label htmlFor="tb_branchCode" className="form-label">
                  Branch Code
                </label>
                <input
                  type="text"
                  id="tb_branchCode"
                  name="tb_branchCode"
                  className="inputBox form-control formControl  text-dark"
                  value={branchDetails.branchCode}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formGroup col-6 mt-1">
                <label htmlFor="tb_swift" className="form-label">
                  Swift
                </label>
                <input
                  type="text"
                  id="tb_swift"
                  name="tb_swift"
                  className="inputBox form-control formControl  text-dark"
                  value={branchDetails.swift}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formGroup col-6 mt-1">
                <label htmlFor="tb_aba" className="form-label">
                  ABA
                </label>
                <input
                  type="text"
                  id="tb_aba"
                  name="tb_aba"
                  className="inputBox form-control formControl  text-dark"
                  value={branchDetails.aba}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formGroup col-12 mt-1">
                <label htmlFor="tb_iBan" className="form-label">
                  iBAN
                </label>
                <input
                  type="text"
                  id="tb_iBan"
                  name="tb_iBan"
                  className="inputBox form-control formControl  text-dark"
                  value={branchDetails.iBan}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formGroup col-md-12">
                <label htmlFor="tb_sortCode" className="form-label">
                  Sort Code
                </label>
                <input
                  type="text"
                  id="tb_sortCode"
                  name="tb_sortCode"
                  className="inputBox form-control formControl text-dark"
                  value={branchDetails.sortCode}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button className="btn_edit1 col-6" type="submit">
              Save
            </button>
          </form>
          <DataTable
            className="resultData"
            columns={columns}
            data={formData}
            pagination
            responsive
            id="myTable"
            customStyles={tableStyles}
          />
        </div>
      </Popup>
    </div>
  );
};

export default EditSuppliersBankDetails;
