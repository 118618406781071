import React, { useState } from "react";
import axios from "axios";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
const EditStaffMainDetails = ({ open }) => {
  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_staffName: "",
    tb_staffType: "",
    tb_contactNumber: "",
    tb_tin: "",
    tb_vrn: "",
    tb_dateOfBirth: "",
    tb_joiningDate: "",
    tb_lastDate:"",
    tb_companyEmailAddress: "",
    tb_physicalAddress: "",
    tb_department: "",
    tb_designation: "",
    tb_digitalSignature: "",
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/Staff/editStaffMainDetailsHandler.php',
        // data: params,
        data: {
          'tb_clientName': formData.tb_clientName,
          'tb_primaryNumber': formData.tb_primaryNumber,
          'tb_secondaryNumber': formData.tb_secondaryNumber,
          'tb_gender': formData.tb_gender,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_poBox': formData.tb_poBox,
          'tb_dob': formData.tb_dob,
          'tb_occupation': formData.tb_occupation,
          'tb_reference': formData.tb_reference,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then(function (response) {
        if (response.status == '200') {
          console.log(response)
          toast.success(response.data['success'], { position: "top-center" });
          navigate(`/Homepage/ViewStaff`);
        }
        console.log(response.status)
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network Error', error.request, { position: "top-center" });
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while Editing Staff details!', { position: "top-center" });
    }
  };

  return (
    <>
    <ToastContainer/>
       <div>
      <Popup open={open} closeOnDocumentClick>
        <div className="editPopup" style={{height: "600px"}}>
        <div className="row ">
        <div className="col-md-12  formGroup">
            <label htmlFor="tb_staffName" className="form-label">
              Staff Name
            </label>
            <input
              type="text"
              id="tb_staffName"
              name="tb_staffName"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_staffType" className="form-label">
              Staff Type
            </label>
            <input
              type="text"
              id="tb_staffType"
              name="tb_staffType"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_contactNumber" className="form-label">
             Contact Number
            </label>
            <input
              type="number"
              id="tb_contactNumber"
              name="tb_contactNumber"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-8  formGroup">
            <label htmlFor="tb_emailAddress" className="form-label">
              Email Address
            </label>
            <input
              type="email"
              id="tb_emailAddress"
              name="tb_emailAddress"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4  formGroup">
            <label htmlFor="tb_dateOfBirth" className="form-label">
              Date Of Birth
            </label>
            <input
              type="text"
              id="tb_dateOfBirth"
              name="tb_dateOfBirth"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_joiningDate" className="form-label">
              Joining Date
            </label>
            <input
              type="text"
              id="tb_joiningDate"
              name="tb_joiningDate"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_lastDate" className="form-label">
              Last Date
            </label>
            <input
              type="text"
              id="tb_lastDate"
              name="tb_lastDate"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_department" className="form-label">
              Department
            </label>
            <input
              type="text"
              id="tb_department"
              name="tb_department"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
        <div className="col-md-6  formGroup">
            <label htmlFor="tb_designation" className="form-label">
              Designation
            </label>
            <input
              type="text"
              id="tb_designation"
              name="tb_designation"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          {/* Contact Number Field */}
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_physicalAddress" className="form-label">
              Physical Address
            </label>
            <textarea
              type="text"
              id="tb_physicalAddress"
              name="tb_physicalAddress"
              className="inputBox form-control textarea  text-dark tb_address"
              rows={5}
              cols="100"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
                  <label htmlFor="tb_tin" className="form-label">
                    TIN
                  </label>
                  <input
                    type="text"
                    id="tb_tin"
                    name="tb_tin"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-6">
                  <label htmlFor="tb_vrn" className="form-label">
                    VRN
                  </label>
                  <input
                    type="text"
                    id="tb_vrn"
                    name="tb_vrn"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-12">
                  <label htmlFor="tb_signature" className="form-label">
                    Digital Signature
                  </label>
                  <input
                    type="text"
                    id="tb_digitalSignature"
                    name="tb_digitalSignature"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
        </div>
        <div className="row btnGroup">
          <div className="col-md-10">
            <button type="submit" className="btn_edit1 col-8" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
        </div>
        </Popup>
        </div>
    </>
  );
};

export default EditStaffMainDetails;
