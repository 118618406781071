import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "../../../../optoCustom.js";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
const AddStaff = () => {
  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_staffName: "",
    tb_staffType: "",
    tb_contactNumber: "",
    tb_emailAddress: "",
    tb_physicalAddress: "",
    tb_joiningDate: "",
    tb_dob: "",
    db_departmentName: "",
    db_designationName: "",
    tb_tin: "",
    tb_vrn: "",
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Datetimepicker
  const [datetimeVal, setDatetimeVal] = useState("");

  const handleDatetimePicker = (event) => {
    setDatetimeVal(event.target.value);
    console.log(datetimeVal);
  };

  // Single select
  const [singleselectedOption, setsingleSelectedOption] = useState("");

  const handleSingleSelect = (singleselectedOption) => {
    setsingleSelectedOption(singleselectedOption);
    console.log(singleselectedOption);
  };

  const [departmentOptions, setdepartmentOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios.get(
          "https://handler.optolinq.online/COMMON/getDepartmentList.php"
        ); // Replace with your API endpoint
        const options = response.data.map((option) => ({
          value: option.department_id, // Adjust based on your API response structure
          label: option.department_name, // Adjust based on your API response structure
        }));
        setdepartmentOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  const [designationOptions, setdesignationOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios.get(
          "https://handler.optolinq.online/COMMON/getDesignationList.php"
        ); // Replace with your API endpoint
        const options = response.data.map((option) => ({
          value: option.designation_id, // Adjust based on your API response structure
          label: option.designation_name, // Adjust based on your API response structure
        }));
        setdesignationOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/Staff/addStaffHandler.php",
        // data: params,
        data: {
          tb_staffName: formData.tb_staffName,
          tb_clientName: formData.tb_clientName,
          tb_contactNumber: formData.tb_contactNumber,
          tb_secondaryNumber: formData.tb_secondaryNumber,
          tb_gender: formData.tb_gender,
          tb_emailAddress: formData.tb_emailAddress,
          tb_physicalAddress: formData.tb_physicalAddress,
          tb_poBox: formData.tb_poBox,
          tb_dob: formData.tb_dob,
          db_departmentName: formData.db_departmentName,
          db_designationName: formData.db_designationName,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then(function (response) {
          if (response.status == "200") {
            console.log(response);
            toast.success(response.data["success"], { position: "top-center" });
            navigate(`/Homepage/ViewClient`);
          }
          console.log(response.status);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === "401") {
              toast.error(error.response.data["error"], {
                position: "top-center",
              });
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else if (error.request) {
            console.error("Network Error:", error.request);
            toast.error("Network Error", error.request, {
              position: "top-center",
            });
          } else {
            console.log("Error:", error.message);
          }
        });
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while Adding a new Staff!", {
        position: "top-center",
      });
    }
  };

  return (
    <>
      <ToastContainer />
      {/* Heading and Sub Heading */}
      <div className="formHeader d-flex align-items-center justify-content-between">
        <div className="headings">
          <div className="formHeading">ADD STAFF</div>
          <div className="formSubHeading">
            Fill up the details below to add a new Staff
          </div>
        </div>
        <Link to={"/Homepage/SearchStaff"}>
          <button className="btnClose d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#ffffff", height: "20px" }}
              className="mx-auto my-auto"
            />
          </button>
        </Link>
      </div>
      <hr />
      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row ">
          {/* String Fields */}
          <div className="col-md-12  formGroup">
            <label htmlFor="tb_staffName" className="form-label">
              Staff Name
            </label>
            <input
              type="text"
              id="tb_staffName"
              name="tb_staffName"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-6">
            <label htmlFor="singleSelect">Staff Type</label>
            <Select
              options={designationOptions}
              placeholder="Staff Type"
              id="singleSelect"
              name="tb_staffType"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #B0B0B0",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable
              // isDisabled
              isClearable
              value={singleselectedOption}
              onChange={handleSingleSelect}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_contactNumber" className="form-label">
              Contact Number
            </label>
            <input
              type="number"
              id="tb_contactNumber"
              name="tb_contactNumber"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-12  formGroup">
            <label htmlFor="tb_emailAddress" className="form-label">
              Email Address
            </label>
            <input
              type="email"
              id="tb_emailAddress"
              name="tb_emailAddress"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          {/* Datetimepicker */}
          <div className="formGroup col-6 formGroup">
            <label htmlFor="tb_dob" className="">
              Date Of Birth
            </label>
            <input
              className="form-control formControl"
              type="date"
              id="tb_dob"
              name="tb_dob"
              // value={datetimeVal} // Bind the input value to the state
              onChange={handleChange} // Call the handler on change
            />
          </div>
          {/* Datetimepicker */}
          <div className="formGroup col-6 formGroup">
            <label htmlFor="tb_joiningDate" className="">
              Joining Date
            </label>
            <input
              className="form-control formControl"
              type="date"
              id="tb_joiningDate"
              name="tb_joiningDate"
              value={datetimeVal} // Bind the input value to the state
              onChange={handleDatetimePicker} // Call the handler on change
            />
          </div>
          <div className="formGroup col-6">
            <label htmlFor="db_departmentName">Department</label>
            <Select
              options={departmentOptions}
              placeholder="Department"
              id="db_departmentName"
              name="db_departmentName"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #B0B0B0",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable
              // isDisabled
              isClearable
              value={singleselectedOption}
              onChange={handleSingleSelect}
            />
          </div>
          <div className="formGroup col-6">
            <label htmlFor="db_designationName">Designation</label>
            <Select
              options={designationOptions}
              placeholder="Designation"
              id="db_designationName"
              name="db_designationName"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #B0B0B0",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable
              // isDisabled
              isClearable
              value={singleselectedOption}
              onChange={handleSingleSelect}
            />
          </div>
          {/* Contact Number Field */}
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_physicalAddress" className="form-label">
              Physical Address
            </label>
            <textarea
              type="text"
              id="tb_physicalAddress"
              name="physicalAddress"
              className="inputBox form-control textarea  text-dark tb_address"
              rows={5}
              cols="100"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_tin" className="form-label">
              TIN
            </label>
            <input
              type="text"
              id="tb_tin"
              name="tb_tin"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_vrn" className="form-label">
              VRN
            </label>
            <input
              type="text"
              id="tb_vrn"
              name="tb_vrn"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row btnGroup">
          <div className="col-md-6 ">
            <button type="submit" className="btnCTA">
              Save
            </button>
          </div>
          <div className="col-md-6 ">
            <button
              type="button"
              className="btnCTA"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddStaff;
