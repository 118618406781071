
import ViewPatient from '../HomePage/Masters/Patients/ViewPatient';
import Formtemplate from '../HomePage/Formtemplate';
import ClientSearch from '../HomePage/Masters/Client/ClientSearch';
import AddClient from '../HomePage/Masters/Client/AddClient';
import PatientSearch from '../HomePage/Masters/Patients/PatientSearch';
import SearchSuppliers from '../HomePage/Masters/Suppliers/SearchSuppliers';
import ViewSuppliers from '../HomePage/Masters/Suppliers/ViewSuppliers';
import AddSuppliers from '../HomePage/Masters/Suppliers/AddSuppliers';
import SearchStaff from '../HomePage/Masters/Staff/SearchStaff';
import AddStaff from '../HomePage/Masters/Staff/AddStaff';
import ViewStaff from '../HomePage/Masters/Staff/ViewStaff';
import SearchDiagnosis from '../HomePage/Diagnosis/SearchDiagnosis';
import AddDiagnosis from '../HomePage/Diagnosis/AddDiagnosis';
import ViewDiagnosis from '../HomePage/Diagnosis/ViewDiagnosis';
import ViewClient from '../HomePage/Masters/Client/ViewClient';
import AddPatient from '../HomePage/Masters/Patients/AddPatient';
import AddCompany from '../GodMode/AddCompany/AddCompany';
import Company from '../GodMode/Company/Company';
import View from '../GodMode/View/View';





const GodpageRoutes = [
    { path: '/AddCompany', name: 'AddCompany', component: AddCompany },
    { path: '/Company', name: 'Company', component: Company },
    { path: '/AddCompany', name: 'AddCompany', component: AddCompany },
    // { path: '/View', name: 'View', component: View },
    { path: '/View/:companyId', name: 'View', component: View },
]

const Homepageroutes = [
    // { path: 'ViewPatient', name: 'ViewPatient', component: ViewPatient },
    { path: 'ViewPatient/:patientId', name: 'ViewPatient', component: ViewPatient },
    { path: 'Formtemplate', name: 'Formtemplate', component: Formtemplate },
    { path: 'ClientSearch', name: 'ClientSearch', component: ClientSearch },
    { path: 'AddClient', name: 'AddClient', component: AddClient },
    { path: 'PatientSearch', name: 'PatientSearch', component: PatientSearch },
    { path: 'SearchSuppliers', name: 'SearchSuppliers', component: SearchSuppliers },
    // { path: 'ViewSuppliers', name: 'ViewSuppliers', component: ViewSuppliers },\
    { path: 'ViewSuppliers/:supplierId', name: 'ViewSuppliers', component: ViewSuppliers },
    { path: 'AddSuppliers', name: 'AddSuppliers', component: AddSuppliers },
    { path: 'SearchStaff', name: 'SearchStaff', component: SearchStaff },
    { path: 'AddStaff', name: 'AddStaff', component: AddStaff },
    // { path: 'ViewStaff', name: 'ViewStaff', component: ViewStaff },
    { path: 'ViewStaff/:staffId', name: 'ViewStaff', component: ViewStaff },
    { path: 'SearchDiagnosis', name: 'SearchDiagnosis', component: SearchDiagnosis },
    { path: 'AddDiagnosis', name: 'AddDiagnosis', component: AddDiagnosis },
    { path: 'ViewDiagnosis', name: 'ViewDiagnosis', component: ViewDiagnosis },
    // { path: 'ViewClient', name: 'ViewClient', component: ViewClient },
    { path: 'ViewClient/:clientId', name: 'ViewClient', component: ViewClient },
    { path: 'AddPatient', name: 'AddPatient', component: AddPatient },
];




export { Homepageroutes, GodpageRoutes };

