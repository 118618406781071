import React from "react";
import { Link } from "react-router-dom";
import ViewPatient from "../Masters/Patients/ViewPatient";
import { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faDownload } from "@fortawesome/free-solid-svg-icons";
import AddPrescriptionPopup from "../../HomePage/Diagnosis/AddPrescriptionPopup";
import AddDiagnosisPopup from "../../HomePage/Diagnosis/AddDiagnosisPopup";
import ViewClient from "../Masters/Client/ViewClient";
const ViewDiagnosis = () => {
    const [formData, setData] = useState([]);
    const [AddDiagnosisIsOpen, setAddDiagnosisIsOpen] = useState(false);
    const [AddPrescriptionIsOpen, setAddPrescriptionIsOpen] = useState(false);

    const toggleAddPrescriptionPopup = () => {
      setAddPrescriptionIsOpen(!AddPrescriptionIsOpen);
    };

    const toggleAddDiagnosisPopup = () => {
      setAddDiagnosisIsOpen(!AddDiagnosisIsOpen);
    };
  useEffect(() => {
    const fetchData = async () => {
      axios
        .get(
          "https://jsonplaceholder.typicode.com/users"
        )
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    };
    fetchData();
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://handler.optolinq.online/DIAGNOSIS/deletePrescriptionHandler.php",
        formData
      );
      console.log("Document has been Deleted successfully", response.data);
      toast.success("Document has been Deleted successfully",response.data['success'], { position: "top-center" });
    } catch (error) {
      console.error("There was an error in deleting the document!",error.response);
      toast.error("There was an error in deleting the document!",error.response, { position: "top-center" });
    }
  };

    const tableStyles = {
        headRow: {
          style: {
            backgroundColor: "#E6E6E6",
            fontWeight: 600,
            borderBottom: "2px solid #D7D8D8",
          },
        },
        headCells: {
          style: {},
        },
        rows: {
          style: {},
        },
        cells: {
          style: {},
        },
      };
      const columns = [
        {
          name: "Prescription Details",
          // selector: (row) => row.id,
          sortable: true,
        },
        {
          name: "Course Duration",
          // selector: (row) => row.name,
        },
        {
          name: "Follow up Date",
          // selector: (row) => row.address.street,
        },
        {
          name: "Prescription Date",
          // selector: (row) => row.phone,
        },
       
    
        {
          name: "Download",
          cell: (row) => (
            <button
              type="button"
              className="btnEYE"
              style={{ border: "none", background: "none" }}
            >
              <Link to={"/Hompage/ViewDiagnosis"}>
                <FontAwesomeIcon
                  className="actionIcon"
                  icon={faDownload}
                  style={{
                    color: "#717171",
                    width: "25px",
                    height: "auto",
                  }}
                />
              </Link>
            </button>
          ),
        },
        {
          name: "Delete",
          cell: (row) => (
            <button
              type="button"
              className="btnEYE"
              style={{ border: "none", background: "none" }}
              onClick={handleDelete}
            >
                <FontAwesomeIcon
                  className="actionIcon"
                  icon={faTrashCan}
                  style={{
                    color: "#717171",
                    width: "25px",
                    height: "auto",
                  }}
                />
            </button>
          ),
        },
      ];
    
    
  return (
    <div className="viewFormContainer row">
    <div className="formHeading">VIEW DIAGNOSIS</div>
      <div className="formSubHeading">
        View the Diagnosis details below 
      </div>
      <hr />
      <form className="inputContent col-md-9">
        <div className="row ">
          {/* String Fields */}
          <div className="col-md-6  formGroup">
            <label htmlFor="patientName" className="form-label">
              Patient Name
            </label>
            <input
              type="text"
              id="tb_patientName"
              name="patientName"
              className="inputBox form-control form_Control text-dark"
              readOnly
              
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="clientName" className="form-label">
              Client Name
            </label>
            <input
              type="text"
              id="tb_clientName"
              name="clientName"
              className="inputBox form-control form_Control text-dark"
              readOnly
              
            />
          </div>
          <div className="col-md-6 formGroup">
            <label htmlFor="tb_age" className="form-label">
              Age
            </label>
            <input
              type="text"
              id="tb_age"
              name="tb_age"
              className="inputBox form-control form_Control text-dark"
              readOnly
              
            />
          </div>
          <div className="formGroup col-md-6">
                  <label htmlFor="tb_gender" className="form-label">
                    Gender
                  </label>
                  <input
                    type="text"
                    id="tb_gender"
                    name="tb_gender"
                    className="inputBox form-control form_Control  text-dark"
                    readOnly
                  />
            </div>
            <div className="formGroup col-md-6">
                  <label htmlFor="tb_diagnosisNumber" className="form-label">
                    Diagnosis Number
                  </label>
                  <input
                    type="text"
                    id="tb_diagnosisNumber"
                    name="tb_diagnosisNumber"
                    className="inputBox form-control form_Control  text-dark"
                    readOnly
                  />
            </div>
          <div className="formGroup col-md-6">
                  <label htmlFor="tb_diagnosisDate" className="form-label">
                    Diagnosis Date
                  </label>
                  <input
                    type="text"
                    id="tb_diagnosisDate"
                    name="tb_diagnosisDate"
                    className="inputBox form-control form_Control  text-dark"
                    readOnly
                  />
            </div>
          {/* Contact Number Field */}
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_healthIssue" className="form-label">
              Health Issue
            </label>
            <textarea
              type="text"
              id="tb_healthIssue"
              name="tb_healthIssue"
              className="inputBox form-control textarea1  text-dark tb_address"
              rows={5}
              cols="100"
              readOnly
              
            />
          </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_vrre" className="form-label">
                    VRRE
                  </label>
                  <input
                    type="text"
                    id="tb_vrre"
                    name="vrre"
                    className="inputBox form-control form_Control  text-dark"
                    readOnly
                    
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_vrle" className="form-label">
                    VRLE
                  </label>
                  <input
                    type="text"
                    id="tb_vrle"
                    name="tb_vrle"
                    className="inputBox form-control form_Control  text-dark"
                    readOnly
                    
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_srre" className="form-label">
                    SRRE
                  </label>
                  <input
                    type="text"
                    id="tb_srre"
                    name="srre"
                    className="inputBox form-control form_Control  text-dark"
                    readOnly
                    
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_srle" className="form-label">
                    SRLE
                  </label>
                  <input
                    type="text"
                    id="tb_srle"
                    name="srle"
                    className="inputBox form-control form_Control  text-dark"
                    readOnly
                    
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_orre" className="form-label">
                    ORRE
                  </label>
                  <input
                    type="text"
                    id="tb_orre"
                    name="orre"
                    className="inputBox form-control form_Control  text-dark"
                    readOnly
                    
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_orle" className="form-label">
                    ORLE
                  </label>
                  <input
                    type="text"
                    id="tb_orle"
                    name="orle"
                    className="inputBox form-control form_Control  text-dark"
                    readOnly
                    
                  />
            </div>
            <div className="col-md-12 formGroup">
            <label htmlFor="tb_notes" className="form-label">
                Notes
            </label>
            <textarea
              type="text"
              id="tb_notes"
              name="tb_notes"
              className="inputBox form-control textarea1  text-dark tb_address"
              rows={5}
              cols="100"
              readOnly
              
            />
          </div>
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_doctorDaignosis" className="form-label">
              Doctor Diagnosis
            </label>
            <textarea
              type="text"
              id="tb_doctorDaignosis"
              name="tb_doctorDaignosis"
              className="inputBox form-control textarea1  text-dark tb_address"
              rows={5}
              cols="100"
              readOnly
              
            />
          </div>
        </div>
      </form>
      <div className="linkContainer col-3 d-flex flex-column">
        <div className="formSubHeading">Quick links</div>
        <p className="links" onClick={toggleAddDiagnosisPopup}>
          Add New Diagnosis
        </p>
        <AddDiagnosisPopup 
        id="AddDiagnosispopup"
        open={AddDiagnosisIsOpen}
        onClose={toggleAddDiagnosisPopup
        }
        />
        <p className="links" onClick={toggleAddPrescriptionPopup}>
          Add New Prescription
        </p>
        <AddPrescriptionPopup 
        id="AddDiagnosispopup"
        open={AddPrescriptionIsOpen}
        onClose={toggleAddPrescriptionPopup
        }
        />
        <Link
          to="/Homepage/ViewPatient"
          element={<ViewPatient/>}
          className="links mb-2 "
        >
          View Patient Details
        </Link>
        <Link
          to="/Homepage/ViewClient"
          element={<ViewClient/>}
          className="links mb-2"
        >
          View Client Details
        </Link>
      </div>
      <hr/>
      <div>
      <div className="formHeading">PRESCRIPTION TABLE</div>
      <DataTable
        className="resultData"
        columns={columns}
        data={formData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      ></DataTable>
      </div>
    </div>
  );
};

export default ViewDiagnosis;
