import React, { useState } from "react";
import "./HomePage.css";
import img_optolinqName from "../../Assets/OPTO LINQ_1080.png";
import optolinqlogo from "../../Assets/OptoIcon.ico";
import pillp from "../../Assets/image.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Dropdown } from "bootstrap/dist/js/bootstrap.bundle.min";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Homepageroutes } from "../Routes/OptoRoute";
import {
  faBars,
  faStethoscope,
  faBorderAll,
  faLayerGroup,
  faBoxOpen,
  faMoneyCheckDollar,
  faCircleChevronDown,
  faBell,
  faArrowRightFromBracket,
  faCalendarCheck,
  faCopyright,
} from "@fortawesome/free-solid-svg-icons";

import { Link, Route, Routes, Outlet } from "react-router-dom";
// import ClientSearch from "./Masters/Client/ClientSearch";
// import AddClient from "./Masters/Client/AddClient";
// import AddPatient from "./Masters/Patients/AddPatient";
// import PatientSearch from "./Masters/Patients/PatientSearch";
// import Formtemplate from "./Formtemplate";
// import ViewClient from "./Masters/Client/ViewClient";
// import ViewPatient from "./Masters/Patients/ViewPatient";
// import SearchSuppliers from "./Masters/Suppliers/SearchSuppliers";
// import ViewSuppliers from "./Masters/Suppliers/ViewSuppliers";
// import AddSuppliers from "./Masters/Suppliers/AddSuppliers";
// import SearchStaff from "./Masters/Staff/SearchStaff";
// import AddStaff from "./Masters/Staff/AddStaff";
// import ViewStaff from "./Masters/Staff/ViewStaff";
// import SearchDiagnosis from "./Diagnosis/SearchDiagnosis";
// import AddDiagnosis from "./Diagnosis/AddDiagnosis";
// import ViewDiagnosis from "./Diagnosis/ViewDiagnosis";

const HomePage = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLinkClick = () => {
    setIsCollapsed(true);
  };

  useEffect(() => {
    const dropdownElements = document.querySelectorAll(
      '[data-bs-toggle="dropdown"]'
    );
    dropdownElements.forEach((dropdownElement) => {
      new Dropdown(dropdownElement);
    });
  }, []);

  return (
    <>
      <div className="d-flex flex-row ">
        {/* Sidebar*/}
        <div
          className={`sidebar d-flex flex-column justify-content-between fixed-top bg-white vh-100  ${
            isCollapsed ? "collapsed" : ""
          }`}
          style={{ minWidth: isCollapsed ? "80px" : "250px" }}
        >
          <div className="p-3 sidebar-top-content" style={{ height: "50px" }}>
            {isCollapsed ? (
              <img
                src={optolinqlogo}
                className="logo2"
                alt="logo2"
                style={{ width: "auto", height: "40px" }}
              />
            ) : (
              <img
                src={img_optolinqName}
                className="logo"
                alt="logo"
                style={{ width: "100%", height: "auto" }}
              />
            )}

            <ul className="nav flex-column text-center">
              <li className={`nav-item ${isCollapsed ? "mx-auto" : ""}`}>
                <Link
                  to={"/HomePage"}
                  className="nav-link  text-black p-2 d-flex align-items-center"
                  onClick={handleLinkClick}
                >
                  <FontAwesomeIcon
                    className="sidebaricon"
                    icon={faBorderAll}
                    style={{ color: "#717171", width: "25px", height: "auto" }}
                  />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className={`nav-item ${isCollapsed ? "mx-auto" : ""}`}>
                <div
                  className={`sideNavDropDown ${isCollapsed ? "dropend" : ""}`}
                >
                  <button
                    type="button"
                    className="sideNavDropDown text-start"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <a
                      href="/r"
                      className="nav-link p-2 text-black d-flex align-items-center justify-content-between"
                    >
                      <div className="nav_dropdown_left_items d-flex align-items-center">
                      <FontAwesomeIcon
                        className="sidebaricon"
                        icon={faLayerGroup}
                        style={{
                          color: "#717171",
                          width: "25px",
                          height: "auto",
                        }}
                      />
                      <span>Masters</span>
                      </div>
                      <div className="nav_dropdown_right_item">
                      <span>
                        <FontAwesomeIcon
                          className="sidebaricon"
                          icon={faCircleChevronDown}
                          style={{
                            color: "#717171",
                            width: "15px",
                            height: "auto",
                          }}
                        />
                      </span>
                      </div>
                    </a>
                  </button>
                  <ul className="dropdown-menu">
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/ClientSearch"}
                        className="p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                        onClick={handleLinkClick}
                      >
                        Search Client
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/AddClient"}
                        className="p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                        onClick={handleLinkClick}
                      >
                        AddClient
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      {" "}
                      <Link
                        to={"/HomePage/ViewClient"}
                        className="p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                        onClick={handleLinkClick}
                      >
                        View Client
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/AddPatient"}
                        className="p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                        onClick={handleLinkClick}
                      >
                        AddPatient
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/PatientSearch"}
                        className="p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                        onClick={handleLinkClick}
                      >
                        Patient Search
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/ViewPatient"}
                        className="p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                        onClick={handleLinkClick}
                      >
                        View Patient
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/SearchSuppliers"}
                        className="p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                        onClick={handleLinkClick}
                      >
                        Search Suppliers
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/AddSuppliers"}
                        className="appiontmentHover p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                      >
                        Add Suppliers
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/ViewSuppliers"}
                        className="appiontmentHover p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                      >
                        View Suppliers
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/SearchStaff"}
                        className="appiontmentHover p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                      >
                        Search Staff
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/AddStaff"}
                        className="appiontmentHover p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                      >
                        Add Staff
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/ViewStaff"}
                        className="appiontmentHover p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                      >
                        View Staff
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className={`nav-item ${isCollapsed ? "mx-auto" : ""}`}>
                <div
                  className={`sideNavDropDown ${isCollapsed ? "dropend" : ""}`}
                >
                  <button
                    type="button"
                    className="sideNavDropDown text-start"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <a
                      href="/t"
                      className="nav-link p-2 text-black d-flex align-items-center justify-content-between"
                    >
                      <div className="nav_dropdown_left_items d-flex align-items-center">
                      <FontAwesomeIcon
                        className="sidebaricon"
                        icon={faStethoscope}
                        style={{
                          color: "#717171",
                          width: "25px",
                          height: "auto",
                        }}
                      />
                      <span>Diagnosis</span>
                      </div>
                      <div className="nav_dropdown_right_item">
                      <span>
                        <FontAwesomeIcon
                          className="sidebaricon"
                          icon={faCircleChevronDown}
                          style={{
                            color: "#717171",
                            width: "15px",
                            height: "auto",
                          }}
                        />
                      </span>
                      </div>
                    </a>
                  </button>
                  <ul className="dropdown-menu">
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/SearchDiagnosis"}
                        className="p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                        onClick={handleLinkClick}
                      >
                        Search Diagnosis
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/AddDiagnosis"}
                        className="p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                        onClick={handleLinkClick}
                      >
                        Add Diagnosis
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      <Link
                        to={"/Homepage/ViewDiagnosis"}
                        className="p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                        onClick={handleLinkClick}
                      >
                        View Diagnosis
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className={`nav-item ${isCollapsed ? "mx-auto" : ""}`}>
                <a
                  href="/r"
                  className="nav-link p-2 text-black d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    className="sidebaricon"
                    icon={faCalendarCheck}
                    style={{ color: "#717171", width: "25px", height: "auto" }}
                  />
                  <span>Appointments</span>
                </a>
              </li>
              <li className={`nav-item ${isCollapsed ? "mx-auto" : ""}`}>
                <a
                  href="/e"
                  className="nav-link p-2 text-black d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    className="sidebaricon"
                    icon={faMoneyCheckDollar}
                    style={{ color: "#717171", width: "25px", height: "auto" }}
                  />
                  <span>Sales</span>
                </a>
              </li>
              <li className={`nav-item ${isCollapsed ? "mx-auto" : ""}`}>
                <div
                  className={`sideNavDropDown ${isCollapsed ? "dropend" : ""}`}
                >
                  <button
                    type="button"
                    className="sideNavDropDown text-start"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <a
                      href="/r"
                      className="nav-link p-2 text-black d-flex align-items-center justify-content-between"
                    >
                      <div className="nav_dropdown_left_items d-flex align-items-center">
                      <FontAwesomeIcon
                        className="sidebaricon"
                        icon={faBoxOpen}
                        style={{
                          color: "#717171",
                          width: "25px",
                          height: "auto",
                        }}
                      />
                      <span>Inventory</span>
                      </div>
                      <div className="nav_dropdown_right_item">
                      <span>
                        <FontAwesomeIcon
                          className="sidebaricon"
                          icon={faCircleChevronDown}
                          style={{
                            color: "#717171",
                            width: "15px",
                            height: "auto",
                          }}
                        />
                      </span>
                      </div>
                    </a>
                  </button>
                  <ul className="dropdown-menu">
                    <li className="dropdown-item">
                      <Link
                        to={"/HomePage/Suppliers"}
                        className="p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                      >
                        Masters
                      </Link>
                    </li>
                    <li className="dropdown-item">
                      {" "}
                      <Link
                        to={"/HomePage/Suppliers"}
                        className="p-2 text-black"
                        style={{ textDecorationLine: "none" }}
                      >
                        Masters
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          {/* Add pillp image at the end of the sidebar */}

          <div className="pillp-container text-center mb-3">
            <img
              src={pillp}
              className="pillp-logo"
              alt="pillp logo"
              style={{ width: "auto", height: "40px" }}
            />
            <p style={{ fontSize: "12px" }}>V 1.0.0</p>

            <FontAwesomeIcon
              className="sidebaricon"
              icon={faCopyright}
              style={{ color: "#717171", width: "15px", height: "auto" }}
            />
            <p style={{ fontSize: "12px" }}>2024-25</p>
          </div>
        </div>

        {/*Header*/}
        <div className="flex-grow-1 d-flex flex-column" id="mainContent">
          <nav className="navbar bg-body-tertiary d-flex flex-row justify-content-between align-items-center sticky-top">
            <div className="div1 d-flex align-items-center">
              <img
                src={optolinqlogo}
                className="logo2-navbar"
                alt="logo2"
                style={{ width: "auto", height: "40px" }}
              />
              <FontAwesomeIcon
                icon={faBars}
                onClick={toggleSidebar}
                className="menu mr-3 space-right2 cursor-pointer"
              />
              <div className="page-name ms-2">My Profile</div>
              <div className="page-name ms-2">Help Desk</div>
            </div>
            <div className="div2 d-flex align-items-center justify-content-between ">
              <FontAwesomeIcon
                className="navbaricon mx-3"
                icon={faBell}
                style={{ color: "#ffffff", width: "20px", height: "auto" }}
              />
              <FontAwesomeIcon
                className="navbaricon mx-3"
                icon={faArrowRightFromBracket}
                style={{ color: "#ffffff", width: "20px", height: "auto" }}
              />
            </div>
          </nav>
          <div className="innerContent flex-grow-1">
            {/* Breadcrumb */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/HomePage"} className="link">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a href="/" className="link">
                    Library
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href="/" className="link">
                    Data
                  </a>
                </li>
              </ol>
            </nav>

            {/* Main Content */}
            <div className="formCard flex-grow-1 ">
              <Routes>
                {/* <Route path="/Formtemplate" element={<Formtemplate />}></Route>
                <Route path="/ClientSearch" element={<ClientSearch />}></Route>
                <Route path="/AddClient" element={<AddClient />}></Route>
                <Route path="/ViewClient" element={<ViewClient/>}></Route>
                <Route path="/AddPatient" element={<AddPatient/>}></Route>
                <Route path="/PatientSearch" element={<PatientSearch/>}></Route>
                <Route path="/ViewPatient" element={<ViewPatient/>}></Route>
                <Route path="/SearchSuppliers" element={<SearchSuppliers/>}></Route>
                <Route path="/ViewSuppliers" element={<ViewSuppliers/>}></Route>
                <Route path="/AddSuppliers" element={<AddSuppliers/>}></Route>
                <Route path="/SearchStaff" element={<SearchStaff/>}></Route>
                <Route path="/AddStaff" element={<AddStaff/>}></Route>
                <Route path="/ViewStaff" element={<ViewStaff/>}></Route>
                <Route path="/SearchDiagnosis" element={<SearchDiagnosis/>}></Route>
                <Route path="/AddDiagnosis" element={<AddDiagnosis/>}></Route>
                <Route path="/ViewDiagnosis" element={<ViewDiagnosis/>}></Route> */}
                {Homepageroutes.map((route, idx) => {
                  const Component = route.component; // Store component in a variable
                  return (
                    <Route
                      key={idx} // It's important to give each route a unique key
                      path={route.path}
                      element={<Component />} // Render component as JSX
                    />
                  );
                })}
              </Routes>
              <Outlet />
            </div>
          </div>
          {/* <div className="footer-div fixed-bottom">
            <div className="footer d-flex flex-row justify-content-between">
              <div className="left-part mx-3">Copyright Information</div>
              <div className="right-part d-flex flex-row mx-3">
                <div className="loginCurrent mx-3">Current Login : XX:XX</div>
                <div className="loginLast mx-3">Last Login : XX:XX</div>
                <div className="version mx-3">Version X.1.2.3</div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default HomePage;
