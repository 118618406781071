import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";
import img_optolinqName from "../../../Assets/OPTO LINQ_1080.png";
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Card,
  Form,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
  FloatingLabel,
} from "react-bootstrap";

const Login = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotLink, setShowForgotLink] = useState(false);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const labelstyle = {
    color: "#e0e0e0",
    fontSize: "14px",
    opacity: "50%",
  };

  const linkstyle = {
    color: "#BB86FC",
    fontSize: "14px",
    margin: "auto",
    marginBottom: "0px",
    padding: "0px",
    border: "none",
  };

  const inputStyle = {
    backgroundColor: "#333333",
    borderRadius: "30px",
    color: "#ffffff",
    maxWidth:" 350px",
    margin: "auto",
    border: "none",
    height: "50px"
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (username.length === 0 || password.length === 0) {
      setShowForgotLink(true);
      toast.error("Username and Password are required", {
        position: "top-center",
      });
      return;
    }
  
    try {
      const params = new URLSearchParams();
      params.append('tb_userNameField', username);
      params.append('tb_passwordField', password);
      params.append('opto_domain', window.location.hostname);
      axios({
        method: 'post',
        url: API_BASE_URL + 'IAMS/loginHandler.php',
        data: params
      }).then(function (response) {
        console.log(response)
        if (response.status == 200) {
          toast.success("Login successful!", { position: "top-center" });
          setShowForgotLink(false);
          navigate(`/Homepage`);
        } else if (response.status == 201) {
          toast.success("Logged in Successfully!", { position: "top-center" });
          setShowForgotLink(false);
          navigate(`/GodPage`);
        } else if (response.status === 401) {
          toast.error(response.data['error'], { position: "top-center" });
          setShowForgotLink(true);
        } else {
          toast.error("Unknown error occurred", { position: "top-center" });
        }
      }).catch(function (error) {
        if (error.response) {
          toast.error(error.message, { position: "top-center" });
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network error, please try again', { position: "top-center" });
        } else {
          console.error('Error:', error.message);
          toast.error('An error occurred', { position: "top-center" });
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred during login', { position: "top-center" });
    }
  };

  return (
    <div>
      <ToastContainer />
      <Container className="cont_login d-flex justify-content-center align-items-center min-vh-100">
        <Card className="p-4" style={{ maxWidth: "400px", width: "100%" }}>
          <Card.Body>
            <br />
            <div className="img_optolinqName text-center mb-3">
              <img
                src={img_optolinqName}
                alt="logo"
                style={{ width: "95%", height: "auto" }}
              />
            </div>
            <Form onSubmit={handleSubmit} className="frm_login mt-3">
              <div className="text-center mt-4 name mx-auto" id="sub-heading">
                Enter your credentials to sign in
              </div>

              <FormGroup className="tb_userNameField d-flex flex-column align-items-left mb-3 mx-auto" style={{maxWidth: "350px"}}>
                <FloatingLabel htmlFor="username" className="username" style={labelstyle}>
                  Username
                </FloatingLabel>
                <InputGroup>
                  <FormControl
                    type="text"
                    id="tb_userNameField"
                    name="tb_userNameField"
                    className="tb_userNameFieldInput"
                    placeholder="Username"
                    value={username}
                    onChange={handleUsernameChange}
                    style={inputStyle}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup className="tb_passwordField flex flex-column align-items-left mx-auto mb-3" style={{ maxWidth: "350px" }}>
                <FloatingLabel htmlFor="password" className="password" style={labelstyle}>
                  Password
                </FloatingLabel>
                <InputGroup>
                  <FormControl
                    type="password"
                    id="tb_passwordField"
                    name="tb_passwordField"
                    className="tb_passwordFieldInput"
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    style={inputStyle}
                  />
                </InputGroup>
              </FormGroup>
              <div className="mx-auto" style={{ maxWidth: "350px" }}>
                {showForgotLink && (
                  <Link to={"/ForgotCredientials"} style={linkstyle}>
                    Forgot Credentials? Click Here
                  </Link>
                )}
              </div>
              <div className="d-flex ">
                <Button
                  type="submit"
                  id="btn_login"
                  name="btn_login"
                  className="btn_login mt-3 w-100 mx-auto"
                >
                  Sign in
                </Button>
              </div>
              <br />
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Login;
