import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "../../../../optoCustom.js";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const AddClient = () => {
  const navigate = useNavigate();
  // // State to handle form data
  const [formData, setFormData] = useState({
    tb_clientName: "",
    tb_primaryNumber: "",
    tb_secondaryNumber: "",
    tb_gender: "",
    tb_emailAddress: "",
    tb_physicalAddress: "",
    tb_poBox: "",
    tb_dob: "",
    tb_occupation: "",
    tb_reference: "",
  });

  useEffect(()=>{
    console.log(formData)
  }, [formData]);


  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // console.log(e.target.name);
    // console.log(e.target.value);
  };
  // // Datetimepicker

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData.tb_clientName);
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/Client/addClientHandler.php",
        // data: params,
        data: {
          tb_clientName: formData.tb_clientName,
          tb_primaryNumber: formData.tb_primaryNumber,
          tb_secondaryNumber: formData.tb_secondaryNumber,
          tb_gender: formData.tb_gender,
          tb_emailAddress: formData.tb_emailAddress,
          tb_physicalAddress: formData.tb_physicalAddress,
          tb_poBox: formData.tb_poBox,
          tb_dob: formData.tb_dob,
          tb_occupation: formData.tb_occupation,
          tb_reference: formData.tb_reference,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then(function (response) {
          console.log(response)
          if (response.status == "200") {
            console.log(response);
            toast.success(response.data["success"], { position: "top-center" });
            navigate(`/Homepage/ViewClient`);
          }
          console.log(response.status);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === "401") {
              
              toast.error(error.response.data["error"], {
                position: "top-center",
              });
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else if (error.request) {
            console.error("Network Error:", error.request);
            toast.error("Network Error", error.request, {
              position: "top-center",
            });
          } else {
            console.log("Error:", error.message);
          }
        });
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while Adding a new Client!", {
        position: "top-center",
      });
    }
  };

  return (
    <>
      <ToastContainer />
      {/* Heading and Sub Heading */}
      <div className="formHeader d-flex align-items-center justify-content-between">
        <div className="headings">
          <div className="formHeading">ADD CLIENT</div>
          <div className="formSubHeading">
            Fill up the details below to add a new Client
          </div>
        </div>
        <Link to={"/Homepage/ClientSearch"}>
          <button className="btnClose d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#ffffff", height: "20px" }}
              className="mx-auto my-auto"
            />
          </button>
        </Link>
      </div>
      <hr />
      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row ">
          {/* String Fields */}
          <div className="col-md-12  formGroup">
            <label htmlFor="tb_clientName" className="form-label">
              Client Name
            </label>
            <input
              type="text"
              id="tb_clientName"
              name="tb_clientName"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_primaryNumber" className="form-label">
              Primary Contact
            </label>
            <input
              type="number"
              id="tb_primaryNumber"
              name="tb_primaryNumber"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_secondaryNumber" className="form-label">
              Secondary Contact
            </label>
            <input
              type="number"
              id="tb_secondaryNumber"
              name="tb_secondaryNumber"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-8  formGroup">
            <label htmlFor="tb_emailAddress" className="form-label">
              Email Address
            </label>
            <input
              type="email"
              id="tb_emailAddress"
              name="tb_emailAddress"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-4">
            <label className="form-check-label" htmlFor="gender">
              Gender
            </label>
            <div className="radioGroup d-flex justify-content-start">
              <div className="form-check me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tb_gender"
                  id="genderMale"
                  value="male"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="genderMale">
                  Male
                </label>
              </div>
              <div className="form-check me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tb_gender"
                  id="genderFemale"
                  value="female"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="genderFemale">
                  Female
                </label>
              </div>
            </div>
          </div>
          {/* Contact Number Field */}
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_physicalAddress" className="form-label">
              Physical Address
            </label>
            <textarea
              type="text"
              id="tb_physicalAddress"
              name="tb_physicalAddress"
              className="inputBox form-control textarea  text-dark tb_address"
              rows={5}
              cols="100"
              onChange={handleChange}
            />
          </div>
          <div className="col-6  formGroup">
            <label htmlFor="tb_poBox" className="form-label">
              Post Box
            </label>
            <input
              type="text"
              id="tb_poBox"
              name="tb_poBox"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          {/* Datepicker */}
          <div className="formGroup col-6 formGroup">
            <label htmlFor="tb_dob" className="">
              Date Of Birth
            </label>
            <input
              className="form-control formControl"
              type="date"
              id="tb_dob"
              name="tb_dob"
              
              // value={datetimeVal} // Bind the input value to the state
              onChange={handleChange} // Call the handler on change
            />
          </div>
          <div className="col-6  formGroup">
            <label htmlFor="tb_occupation" className="form-label">
              Occupation
            </label>
            <input
              type="text"
              id="tb_occupation"
              name="tb_occupation"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-6  formGroup">
            <label htmlFor="tb_reference" className="form-label">
              Reference
            </label>
            <input
              type="text"
              id="tb_reference"
              name="tb_reference"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row btnGroup">
          <div className="col-md-6 ">
            <button type="submit" className="btnCTA" onClick={handleSubmit}>
              Save
            </button>
          </div>
          <div className="col-md-6 ">
            <button
              type="button"
              className="btnCTA"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddClient;
