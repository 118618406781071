import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
const AddSuppliers = () => {
  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_supplier: '',
    tb_shortName: '',
    tb_supplierType: '',
    tb_tin: '',
    tb_vrn: '',
    tb_physicalAddress: '',
    tb_postBox: '',
    tb_city: '',
    tb_primaryNumber: '',
    tb_secondaryNumber: '',
    tb_emailAddress: '',
    tb_contactName: '',
    tb_designation: '',
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/Supplier/addSupplierHandler.php',
        // data: params,
        data: {
          'tb_supplier': formData.tb_supplier,
          'tb_shortName': formData.tb_shortName,
          'tb_supplierType': formData.tb_supplierType,
          'tb_tin': formData.tb_tin,
          'tb_vrn': formData.tb_vrn,
          'tb_secondaryNumber': formData.tb_secondaryNumber,
          'tb_primaryNumber': formData.tb_primaryNumber,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_contactName': formData.tb_contactName,
          'tb_postBox': formData.tb_postBox,
          'tb_city': formData.tb_city,
          'tb_contactdesignation': formData.tb_contactdesignation,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then(function (response) {
        if (response.status == '200') {
          console.log(response)
          toast.success(response.data['success'], { position: "top-center" });
          navigate(`/Homepage/ViewSuppliers`);
        }
        console.log(response.status)
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network Error', error.request, { position: "top-center" });
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while Adding a new Supplier!', { position: "top-center" });
    }
  };
  return (
    <>
    <ToastContainer/>
      {/* Heading and Sub Heading */}
      <div className="formHeader d-flex align-items-center justify-content-between">
        <div className="headings">
          <div className="formHeading">ADD SUPPLIER</div>
          <div className="formSubHeading">
            Fill up the details below to add a new Supplier
          </div>
        </div>
        <Link to={"/Homepage/SearchSuppliers"}>
          <button className="btnClose d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#ffffff", height: "20px" }}
              className="mx-auto my-auto"
            />
          </button>
        </Link>
      </div>
      <hr />
      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
      <div className="row mt-1">
                <div className="formGroup col-md-12 mt-1">
                  <label htmlFor="tb_supplier" className="form-label">
                    Supplier Name
                  </label>
                  <input
                    type="text"
                    id="tb_supplier"
                    name="tb_supplier"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-6">
                  <label htmlFor="tb_shortName" className="form-label">
                    Short Name
                  </label>
                  <input
                    type="text"
                    id="tb_shortName"
                    name="tb_shortName"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-6">
                  <label htmlFor="tb_supplierType" className="form-label">
                    Supplier Type
                  </label>
                  <input
                    type="text"
                    id="tb_supplierType"
                    name="tb_supplierType"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-12">
                  <label htmlFor="tb_physicalAddress" className="form-label">
                    Physical Address
                  </label>
                  <input
                    type="text"
                    id="tb_physicalAddress"
                    name="tb_physicalAddress"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-6">
                  <label htmlFor="tb_poBox" className="form-label">
                    P.O.Box
                  </label>
                  <input
                    type="text"
                    id="tb_poBox"
                    name="tb_poBox"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-6">
                  <label htmlFor="tb_city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    id="tb_city"
                    name="tb_city"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-6">
                  <label htmlFor="tb_primaryNumber" className="form-label">
                    Primary Contact
                  </label>
                  <input
                    type="text"
                    id="tb_primaryNumber"
                    name="tb_primaryNumber"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-6">
                  <label htmlFor="tb_secondaryNumber" className="form-label">
                    Secondary Contact
                  </label>
                  <input
                    type="text"
                    id="tb_secondaryNumber"
                    name="tb_secondaryNumber"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-12">
                  <label htmlFor="tb_emailAddress" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="text"
                    id="tb_emailAddress"
                    name="tb_emailAddress"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-6">
                  <label htmlFor="tb_contactName" className="form-label">
                    Contact Person
                  </label>
                  <input
                    type="text"
                    id="tb_contactName"
                    name="tb_contactName"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-6">
                  <label htmlFor="tb_contactdesignation" className="form-label">
                    Designation
                  </label>
                  <input
                    type="text"
                    id="tb_contactdesignation"
                    name="tb_contactdesignation"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-4">
                  <label htmlFor="tb_contactNumber" className="form-label">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    id="tb_contactNumber"
                    name="tb_contactNumber"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-4">
                  <label htmlFor="tb_tin" className="form-label">
                    TIN
                  </label>
                  <input
                    type="text"
                    id="tb_tin"
                    name="tb_tin"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup col-md-4">
                  <label htmlFor="tb_vrn" className="form-label">
                    VRN
                  </label>
                  <input
                    type="text"
                    id="tb_vrn"
                    name="tb_vrn"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <br/>
        <div className="row btnGroup">
        <div className="col-md-6 formGroup">
          <button type="submit" className="btnCTA">
            Save
          </button>
        </div>
        <div className="col-md-6 formGroup">
          <button
            type="button"
            className="btnCTA"
            onClick={() => window.location.reload()}
          >
            Reset
          </button>
        </div>
      </div>
      </form> 
    </>
  );
};

export default AddSuppliers;
