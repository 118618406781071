import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import "./Edit.css";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import { useNavigate } from "react-router-dom";
const EditSMTPDetails = ({ open }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_host: '',
    tb_emailaddress: '',
    tb_password: '',
    tb_port: '',
    tb_secureType: '',
    tb_fromEmailName: '',
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'GODMODE/editSMTPDetailsHandler.php',
        // data: params,
        data: {
          'tb_host': formData.tb_host,
          'tb_emailaddress': formData.tb_emailaddress,
          'tb_password': formData.tb_password,
          'tb_port': formData.tb_port,
          'tb_secureType': formData.tb_secureType,
          'tb_fromEmailName': formData.tb_fromEmailName,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then(function (response) {
        if (response.status == '200') {
          console.log(response)
          toast.success(response.data['success'], { position: "top-center" });
          navigate(`/GodPage/View`);
        }
        console.log(response.status)
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network Error', error.request, { position: "top-center" });
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while Editing SMTP Details!', { position: "top-center" });
    }
  };

  return (
    <div>
      <ToastContainer/>
      <Popup open={open} closeOnDocumentClick>
        <div className="row mt-1 popup">
          <div className="col-12 mt-1">
            <label htmlFor="tb_host" className="form-label">
              Host
            </label>
            <input
              type="text"
              id="tb_host"
              name="tb_host"
              className="inputBox form-control formControl text-dark"
              value={formData.host}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-12 mt-1">
            <label htmlFor="tb_emailaddress" className="form-label">
              Email
            </label>
            <input
              type="text"
              id="tb_emailaddress"
              name="tb_emailaddress"
              className="inputBox form-control formControl text-dark"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 mt-1">
            <label htmlFor="tb_password" className="form-label">
              Password
            </label>
            <input
              type="text"
              id="tb_password"
              name="tb_password"
              className="inputBox form-control formControl text-dark"
              value={formData.password}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 mt-1">
            <label htmlFor="tb_port" className="form-label">
              Port
            </label>
            <input
              type="text"
              id="tb_port"
              name="tb_port"
              className="inputBox form-control formControl text-dark"
              value={formData.port}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 mt-1">
            <label htmlFor="tb_secureType" className="form-label">
              Secure Type
            </label>
            <input
              type="text"
              id="tb_secureType"
              name="tb_secureType"
              className="inputBox form-control formControl text-dark"
              value={formData.secureType}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 mt-1">
            <label htmlFor="tb_fromEmailName" className="form-label">
              From Email Name
            </label>
            <input
              type="text"
              id="tb_fromEmailName"
              name="tb_fromEmailName"
              className="inputBox form-control formControl text-dark"
              value={formData.fromEmailName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <button className="btn_edit1 col-6" onClick={handleSubmit}>
          Add
        </button>
      </Popup>
    </div>
  );
};

export default EditSMTPDetails;
