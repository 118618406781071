import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Mainpopup from "../../../HomePage/Masters/Patients/EditPatientMainDetails"
import Docspopup from "../../../HomePage/Masters/Patients/EditPatientDocumentDetails"
import AddDiagnosisPopup from "../../../HomePage/Diagnosis/AddDiagnosisPopup";
import ViewClient from "../Client/ViewClient";
import axios from "axios";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "../../../../optoCustom";
const ViewPatient = () => {
  const { patientId } = useParams();
  console.log("Client id From Search : " + patientId);
  const [mainIsOpen, setmainIsOpen] = useState(false);
  const [DocsIsOpen, setDocsIsOpen] = useState(false);
  const [AddDiagnosisIsOpen, setAddDiagnosisIsOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [mainDetails, setMainDetails] = useState([]);
  const [documentDetails, setDocumentDetails] = useState([]);
  const [diagnosisDetails, setDiagnosisDetails] = useState([]);
  const [historyDetails, setHistoryDetails] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          method: "post",
          url: API_BASE_URL + "MASTER/Patient/viewPatientDetailsHandler.php",
          data: {
            tb_patientId: patientId,
          },
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
  
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          const responsedData = response.data.OPTO[0].OPTO_MESSAGE;
          setFormData(responsedData);
          setMainDetails(responsedData.main_details[0]);
          setDocumentDetails(responsedData.document_details);
          setDiagnosisDetails(responsedData.diagnosis_details);
          setHistoryDetails(responsedData.history_details);
          console.log(formData);
          console.log(mainDetails);
          console.log(documentDetails);
          console.log(diagnosisDetails);
          console.log(historyDetails);
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            toast.error(error.response.data["error"], {
              position: "top-center",
            });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else {
          toast.error("Network Error", { position: "top-center" });
        }
      }
    };
  
    fetchData();
  }, [patientId]);
  
  const toggleMainPopup = () => {
    setmainIsOpen(!mainIsOpen);
  };

  const toggleDocsPopup = () => {
    setDocsIsOpen(!DocsIsOpen);
  };

  const toggleAddDiagnosisPopup = () => {
    setAddDiagnosisIsOpen(!AddDiagnosisIsOpen);
  };


  const documentAccordionColumns = [
    {
      name: "Document Name",
      selector: (row) => row.document_user_name,
      sortable: true,
    },
    {
      name: "Action",
      cell: () => <button className="btnCTA">View Document</button>,
    },
  ];

  // Diagnosis Table Columns
  const diagnosisAccordionColumns = [
    {
      name: "Diagnosis Date",
      selector: (row) => row.diagnosis_date,
      sortable: true,
    },
    {
      name: "Health Issue",
      selector: (row) => row.health_issue,
      sortable: true,
    },
    {
      name: "Doctor's Diagnosis",
      selector: (row) => row.doctor_diagnosis,
      sortable: true,
    },
  ];

  // History Table Columns (if needed, can be customized)
  const historyAccordionColumns = [
    {
      name: "History Entry",
      selector: (row) => row.some_field, // Example, adapt to your data structure
    },
  ];


  return (
    <div className="viewFormContainer row">
      <div className="formHeading">VIEW PATIENT DETAILS</div>
      <div className="formSubHeading">
      View the Patient details below 
      </div>
      <hr />
      <div className="accordion col-9" id="accordionExample">
        {/* Main Details Section */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Main
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="row mt-1">
                <div className="col-md-12 mt-1">
                  <label className="form-label">Patient Name</label>
                  <input
                    type="text"
                    value={mainDetails.patient_name || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">Client Name</label>
                  <input
                    type="text"
                    value={mainDetails.client_name || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Primary Contact</label>
                  <input
                    type="text"
                    value={mainDetails.primary_number || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Secondary Contact</label>
                  <input
                    type="text"
                    value={mainDetails.secondary_number || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">Email Address</label>
                  <input
                    type="text"
                    value={mainDetails.email_address || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">Physical Address</label>
                  <input
                    type="text"
                    value={mainDetails.physical_address || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Post Box</label>
                  <input
                    type="text"
                    value={mainDetails.post_box || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Date Of Birth</label>
                  <input
                    type="text"
                    value={mainDetails.dob || "N/A"}
                    id="tb_dob"
                    name="tb_dob"
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Occupation</label>
                  <input
                    type="text"
                    value={mainDetails.occupation || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Gender</label>
                  <input
                    type="text"
                    value={mainDetails.gender || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
              </div>
              <br/>
              <div className="row btnGroup">
                <div className="col-md-12">
                  <button className="btn_edit col-12" onClick={toggleMainPopup}>
                    Edit
                  </button>
                  <Mainpopup
                    id="mainDetailspopup"
                    open={mainIsOpen}
                    onClose={toggleMainPopup}
                    value={mainDetails.patientId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Documents Section */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Documents
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <DataTable
                columns={documentAccordionColumns}
                data={documentDetails}
                pagination
                responsive
              />
              <button className="btn_edit col-12" onClick={toggleDocsPopup}>
                Edit
              </button>
              <Docspopup
                id="KnowYourClientDetailspopup"
                open={DocsIsOpen}
                onClose={toggleDocsPopup}
                value={documentDetails.patientId}
              />
            </div>
          </div>
        </div>

        {/* Diagnosis Section */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Diagnosis
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <DataTable
                columns={diagnosisAccordionColumns}
                data={diagnosisDetails}
                pagination
                responsive
              />
              <button
                className="btn_edit col-12"
                onClick={toggleAddDiagnosisPopup}
              >
                Edit/Add Diagnosis
              </button>
              <AddDiagnosisPopup
                id="KnowYourClientDetailspopup"
                open={AddDiagnosisIsOpen}
                onClose={toggleAddDiagnosisPopup}
              />
            </div>
          </div>
        </div>

        {/* History Section */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              History
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <DataTable
                columns={historyAccordionColumns}
                data={historyDetails}
                pagination
                responsive
              />
            </div>
          </div>
        </div>
      </div>
      <div className="linkContainer col-3 d-flex flex-column">
        <div className="formSubHeading">Quick links</div>
        <p className="links" onClick={toggleAddDiagnosisPopup}>
          Add New Diagnosis
        </p>
        <AddDiagnosisPopup 
        id="AddDiagnosispopup"
        open={AddDiagnosisIsOpen}
        onClose={toggleAddDiagnosisPopup
        }
        />
        <Link
          to="/Homepage/ViewClient"
          element={<ViewClient/>}
          className="links mb-2"
        >
          View Client Details
        </Link>
      </div>
    </div>
  );
};


export default ViewPatient;

